import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	List,
	ListItem,
	ListItemText,
	Typography,
	Grid,
	ListItemButton,
	Button,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import { store } from "../../../../store/store.js";
import { toast } from "react-toastify";

import { runLogaritmic } from "./logaritmic";
import { runtwinsStrike } from "./twinsStrike";
import { runSpikeShield } from "./spikeShield.js";
import { runAccumulators } from "./mlAccumulators";
import { runTouchNoTouch } from "./mlTouchNoTouch.js";
import { runHigherLower } from "./mlHigherLower.js";
import { runRiseFall } from "./mlRiseFall";
import { runNiceProfits } from "./niceProfits";
import { runVelandiaSecret } from "./velandiaSecret.js";

const BotsPage = ({ onClose, onOpen }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const tvWidget = store((state) => state.chart);
	const profitTicks = localStorage.getItem("profitTick");
	const chartType = localStorage.getItem("chartType");
	const optionType = localStorage.getItem("optionType");
	const ticks = localStorage.getItem("sliderValue");
	const localBarrier = localStorage.getItem("barrier");
	const [selectedBot, setSelectedBot] = useState("");
	const [windowSize, setWindowSize] = useState(80);
	const [predictionTicks, setPredictionTicks] = useState("");
	const [barrier, setBarrier] = useState(localBarrier || "");
	const [epochs, setEpochs] = useState(3);
	const [mins, setMins] = useState("1440");
	const [slRetCandles, setSLRetCandles] = useState(4);
	const [tpRetCandles, setTPRetCandles] = useState(8);
	const [strategies, setStrategies] = useState([]);
	const [amount, setAmount] = useState("");
	const [showEnlargedImage, setShowEnlargedImage] = useState(false);
	const [threshold, setThreshold] = useState(0.25);

	useEffect(() => {
		const localMins = localStorage.getItem("mins");
		const slRetCandles = localStorage.getItem("slRetCandles");
		const tpRetCandles = localStorage.getItem("tpRetCandles");
		const localAmount = localStorage.getItem("retCandlesAmount");
		const threshold = localStorage.getItem("threshold");

		if (localMins) setMins(localMins);
		if (slRetCandles) setSLRetCandles(parseInt(slRetCandles));
		if (tpRetCandles) setTPRetCandles(parseInt(tpRetCandles));
		if (localAmount) setAmount(parseInt(localAmount));
		if (threshold) setThreshold(threshold);
	}, []);

	useEffect(() => {
		setPredictionTicks(profitTicks ? profitTicks : 5);
	}, [profitTicks]);

	useEffect(() => {
		const maxRetries = 5;
		let retryCount = 0;
		let retryDelay = 1000;

		const attemptFetch = async () => {
			try {
				const response = await fetch("https://mitserver.app:9005/getList", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						table: "strategies",
					}),
					timeout: 10000,
				});

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`);
				}

				const data = await response.json();
				console.log("Data:", data);
				const filteredStrategies = data.filter((strategy) => strategy.chart === chartType && strategy.status === 1);
				console.log("Filtered Strategies:", filteredStrategies);
				setStrategies(filteredStrategies);
				setSelectedBot(filteredStrategies[0]);
			} catch (error) {
				console.log("Fetch error:", error);
				if (retryCount < maxRetries) {
					retryCount++;
					retryDelay *= 1; // Exponential backoff
					setTimeout(attemptFetch, retryDelay);
				} else {
					setStrategies([]);
					toast.error("Error al cargar estrategias. Por favor, intente más tarde.", {
						position: "top-right",
						theme: "dark",
					});
				}
			}
		};

		attemptFetch();
	}, [chartType]);

	const handleBotSelection = (selectedBot) => {
		setSelectedBot(selectedBot);
		switch (selectedBot.name) {
			case "Logaritmic":
				runLogaritmic(tvWidget);
				break;
			case "TwinStrike":
				runtwinsStrike(tvWidget);
				break;
			case "SpikeShield":
				runSpikeShield(tvWidget, mins, slRetCandles, amount);
				break;
			case "Secret":
				runVelandiaSecret(tvWidget, mins, slRetCandles, threshold, amount);
				break;
			case "NiceProfits":
				runNiceProfits(tvWidget, amount);
				break;
			case "Accumulators":
				runAccumulators(tvWidget, epochs, optionType === "accumulators" ? predictionTicks : ticks, windowSize);
				break;
			case "Touch No Touch":
				runTouchNoTouch(tvWidget, epochs, ticks, windowSize);
				break;
			case "Higher Lower":
				runHigherLower(tvWidget, epochs, ticks, windowSize);
				break;
			case "Rise Fall":
				runRiseFall(tvWidget, epochs, ticks, windowSize);
				break;
			default:
				break;
		}
	};
	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "linear-gradient(to bottom, #000000, #000033)",
				padding: 1,
				display: "flex",
				flexDirection: "column",
			}}>
			{/* Upper Section - Selected Bot Details */}
			<Box
				sx={{
					height: "40%",
					mb: 1,
					display: "flex",
					flexDirection: "column",
					gap: 1,
					// overflow: "auto",
				}}>
				{selectedBot && (
					<>
						<img
							src={String(selectedBot.image)}
							alt={selectedBot.name}
							onClick={() => setShowEnlargedImage(true)}
							style={{
								width: "100%",
								height: "180px",
								objectFit: "cover",
								borderRadius: 8,
								boxShadow: "0 0 5px #4599d9, 0 0 20px #4599d9",
								cursor: "pointer",
							}}
						/>
						<Typography
							sx={{
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
								fontSize: "0.8rem",
								mb: 1,
								textAlign: "center",
							}}>
							{selectedBot.description}
						</Typography>

						{chartType === "Opciones" ? (
							<Box
								sx={{
									display: "flex",
									gap: 1,
									flexWrap: "wrap",
									"& .MuiTextField-root": {
										width: "calc(50% - 8px)",
										"& .MuiInputBase-input": {
											padding: "8px 12px",
											fontSize: "0.8rem",
										},
									},
								}}>
								<TextField
									label="Window Size"
									type="number"
									value={windowSize}
									onChange={(e) => setWindowSize(Number(e.target.value))}
									size="small"
								/>
								<TextField
									label="Predecir Ticks"
									type="number"
									value={predictionTicks}
									onChange={(e) => setPredictionTicks(Number(e.target.value))}
									disabled={optionType === "accumulators" ? false : true}
									size="small"
								/>
								{optionType !== "accumulators" && (
									<TextField
										label="Barrera"
										type="text"
										value={barrier}
										onChange={async (e) => {
											setBarrier(Number(e.target.value));
											await localStorage.setItem("barrier", e.target.value);
										}}
										disabled={true}
										size="small"
									/>
								)}
								<TextField
									label="Epochs"
									type="number"
									value={epochs}
									onChange={(e) => setEpochs(Number(e.target.value))}
									size="small"
								/>
							</Box>
						) : (
							<Box
								sx={{
									display: "flex",
									gap: 1,
									flexWrap: "wrap",
									"& .MuiTextField-root": {
										width: "calc(50% - 8px)",
										"& .MuiInputBase-input": {
											padding: "8px 12px",
											fontSize: "0.8rem",
										},
									},
								}}>
								{selectedBot.name === "Secret" && (
									<TextField
										label="Threshold"
										type="text"
										value={threshold}
										onChange={async (e) => {
											const value = e.target.value.replace(",", ".").replace(/[^0-9.]/g, "");
											if (value === "" || !isNaN(value)) {
												setThreshold(Number(value));
												await localStorage.setItem("threshold", value);
											}
										}}
										size="small"
									/>
								)}
								{selectedBot.name === "Secret" && (
									<TextField
										label="Velas SL"
										type="number"
										value={slRetCandles}
										onChange={async (e) => {
											setSLRetCandles(Number(e.target.value));
											await localStorage.setItem("slRetCandles", e.target.value);
										}}
										size="small"
									/>
								)}
								{selectedBot.name === "SpikeShield" && (
									<TextField
										label="Velas TP"
										type="number"
										value={tpRetCandles}
										onChange={async (e) => {
											setTPRetCandles(Number(e.target.value));
											await localStorage.setItem("tpRetCandles", e.target.value);
										}}
										size="small"
									/>
								)}
								{selectedBot.name === "Secret" || selectedBot.name === "NiceProfits" || (
									<TextField
										label="Monto de Trade"
										type="number"
										value={amount}
										onChange={async (e) => {
											setAmount(Number(e.target.value));
											await localStorage.setItem("retCandlesAmount", e.target.value);
										}}
										size="small"
									/>
								)}
							</Box>
						)}

						<Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
							{user.paidOptions.includes("Estrategias") ? (
								<Button
									size="medium"
									onClick={() => handleBotSelection(selectedBot)}
									sx={{
										color: "#fff",
										backgroundColor: "#cc2e3d",
										boxShadow: "0 0 5px#cc2e3d, 0 0 15px#cc2e3d",
										"&:hover": { backgroundColor: "#d93d0d" },
										fontFamily: "'Orbitron', sans-serif",
									}}>
									Activar
								</Button>
							) : (
								<Button
									size="small"
									onClick={() => {
										onClose(false);
										onOpen(true);
									}}
									sx={{
										color: "#fff",
										backgroundColor: "#4599d9",
										boxShadow: "0 0 5px #4599d9, 0 0 15px #4599d9",
										"&:hover": { backgroundColor: "#3d87c3" },
										fontFamily: "'Orbitron', sans-serif",
									}}>
									Comprar
								</Button>
							)}
						</Box>
					</>
				)}
			</Box>

			<Box
				sx={{
					height: "60%",
					borderTop: "1px solid #161a25",
					borderWidth: 2,
					paddingTop: "20px",
					// overflow: "auto",
					// mt: 1,
				}}>
				<Grid container spacing={1}>
					{strategies.map((strategy) => (
						<Grid item xs={6} key={strategy.id}>
							<Box
								onClick={() => setSelectedBot(strategy)}
								sx={{
									p: 1,
									borderRadius: 2,
									backgroundColor: selectedBot?.id === strategy.id ? "#cc2e3d" : "#2a2e39",
									boxShadow:
										selectedBot?.id === strategy.id ? "0 0 10px#cc2e3d, 0 0 30px#cc2e3d" : "0 0 5px#cc2e3d, 0 0 15px#cc2e3d",
									transition: "all 0.3s ease",
									cursor: "pointer",
									"&:hover": {
										transform: "scale(1.02)",
										boxShadow: "0 0 10px#cc2e3d, 0 0 30px#cc2e3d",
									},
									margin: "3px",
								}}>
								<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<Typography
										sx={{
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "0.9rem",
											fontWeight: 700,
										}}>
										{strategy.name}
									</Typography>
									<Typography
										sx={{
											color: "#fff",
											fontSize: "0.9rem",
											fontWeight: 700,
											fontFamily: "'Orbitron', sans-serif",
										}}>
										${strategy.price}
									</Typography>
								</Box>
								<Typography
									sx={{
										color: "rgba(255,255,255,0.7)",
										fontFamily: "'Orbitron', sans-serif",
										fontSize: "0.7rem",
									}}>
									{strategy.userName}
								</Typography>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>

			<Modal
				open={showEnlargedImage}
				onClose={() => setShowEnlargedImage(false)}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<Box
					sx={{
						position: "relative",
						maxWidth: "100vw",
						maxHeight: "100vh",
					}}>
					<img
						src={String(selectedBot?.image)}
						alt={selectedBot?.name}
						style={{
							width: "100%",
							height: "100%",
							objectFit: "contain",
							borderRadius: 8,
							boxShadow: "0 0 15px #4599d9, 0 0 45px #4599d9",
						}}
					/>
				</Box>
			</Modal>
		</Box>
	);
};

export default BotsPage;
