import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Button, Box, TextField } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import socket from "../../../../socket/Socket.js";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { getSymbols } from "../../streaming.js";
import Payments from "./Payments.js";

const ChartTypesPage = ({ onClose }) => {
	const [user, setUser] = useState(null);
	const [initiated, setInitiated] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [showPaymentInputs, setShowPaymentInputs] = useState(false);
	const [selectedPurchaseOption, setSelectedPurchaseOption] = useState(null);
	const [options, setOptions] = useState([]);
	const [cardDetails, setCardDetails] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focused: "",
	});
	const [service, setService] = useState("3in1");
	const [price, setPrice] = useState(14.95);
	const [payments, setPayments] = useState([]);
	const [isLoadingPayments, setIsLoadingPayments] = useState(false);
	const hasFetchedChartTypes = useRef(false);

	useEffect(() => {
		getPaymentHistory();
	}, []);

	const getPaymentHistory = async () => {
		setIsLoadingPayments(true);
		try {
			const user = JSON.parse(localStorage.getItem("user"));
			const response = await axios.post("https://mitserver.app:9005/getList", {
				table: "purchases",
				userId: user.id,
			});
			setPayments(response.data.reverse());
		} catch (error) {
			console.error("Error fetching payments:", error);
			toast.error("Error al cargar historial de pagos", {
				theme: "dark",
			});
		} finally {
			setIsLoadingPayments(false);
		}
	};

	useEffect(() => {
		if (!hasFetchedChartTypes.current) {
			getChartTypes();
			hasFetchedChartTypes.current = true; // Mark as fetched
		}

		const storedUser = JSON.parse(localStorage.getItem("user"));
		setUser(storedUser);

		const storedSelectedOption = localStorage.getItem("chartType");
		if (storedSelectedOption) {
			setSelectedOption(storedSelectedOption);
		}
	}, []);

	const getChartTypes = () => {
		const maxRetries = 3;
		let retryCount = 0;

		const fetchChartTypes = () => {
			try {
				axios
					.post("https://mitserver.app:9005/getList", { table: "chartTypes" })
					.then((response) => {
						setOptions(response.data);
						console.log("Chart types loaded:", response.data);
					})
					.catch((error) => {
						console.error("Error loading chart types:", error);
						if (retryCount < maxRetries) {
							retryCount++;
							console.log(`Retrying attempt ${retryCount} of ${maxRetries}...`);
							setTimeout(fetchChartTypes, 2000 * retryCount);
						}
					});
			} catch (error) {
				console.error("Error in getChartTypes:", error);
				if (retryCount < maxRetries) {
					retryCount++;
					console.log(`Retrying attempt ${retryCount} of ${maxRetries}...`);
					setTimeout(fetchChartTypes, 2000 * retryCount);
				}
			}
		};

		fetchChartTypes();
	};

	const handlePayment = (option) => {
		setPrice(option.price);
		setService(option.key);
		setShowPaymentInputs(true);
		setSelectedPurchaseOption(option);
	};

	const handleSelectOption = (option) => {
		if (option == "Multiplicadores") {
			// localStorage.setItem("optionType", "ACCU");
			getSymbols();
		}
		setSelectedOption(option);
		localStorage.setItem("chartType", option);
		toast.success(`Seleccionado ${option}`, {
			autoClose: 2000,
			theme: "dark",
		});
		onClose();
	};

	const isPaid = (option) => {
		return user && user.paidOptions && user.paidOptions.includes(option);
	};

	return (
		<div>
			<Box
				sx={{
					flex: 1,
					height: "30vh",
					maxHeight: "50%",
					borderRadius: "10px",
					padding: 2,
				}}>
				<Typography
					sx={{
						fontFamily: "'Orbitron', sans-serif",
						fontSize: 18,
						p: 1,
						textAlign: "center",
						color: "#fff",
						textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
					}}
					gutterBottom>
					Tienda
				</Typography>
				<Grid container spacing={2}>
					{options.map((option, index) => {
						const paid = isPaid(option.key);
						const isSelected = selectedOption === option.key;
						return (
							<Grid item xs={6} key={option.id}>
								<div
									className="option-container"
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										maxWidth: "100%",
										height: "100%",
										background: paid ? "#000922" : "#000922",
										borderRadius: "20px",
										padding: "2%",
										boxShadow: paid
											? "0 0 5px #4599d9, 0 0 10px #4599d9, 0 0 15px #4599d9"
											: "0 0 5px #808080, 0 0 10px #808080, 0 0 15px #808080",
										transition: "all 0.3s ease",
										cursor: "pointer",
										"&:hover": {
											transform: "translateY(-5px)",
											boxShadow: paid
												? "0 0 10px #4599d9, 0 0 20px #4599d9, 0 0 30px #4599d9"
												: "0 0 10px #808080, 0 0 20px #808080, 0 0 30px #808080",
										},
									}}>
									<p
										className="option-description"
										style={{
											fontWeight: 800,
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "0.8rem",
											textAlign: "center",
											marginBottom: "-5%",
											transition: "all 0.3s ease",
											"&:hover": {
												color: "#4599d9",
												textShadow: "0 0 5px #4599d9",
											},
										}}>
										{option.title}
									</p>

									<img
										src={option.image}
										alt={option.title}
										style={{
											width: "50%",
											maxWidth: "100px",
											height: "auto",
											borderRadius: "10px",
											marginBottom: "-10%",
											transition: "all 0.3s ease",
											"&:hover": {
												transform: "scale(1.05)",
											},
										}}
									/>
									<p
										className="option-description"
										style={{
											fontWeight: 800,
											color: "#fff",
											fontFamily: "'Orbitron', sans-serif",
											marginBottom: "-0%",
											textAlign: "center",
											transition: "all 0.3s ease",
											"&:hover": {
												color: "#4599d9",
												textShadow: "0 0 5px #4599d9",
											},
										}}>
										${option.price}
									</p>
									{paid ? (
										<Button
											onClick={() => {
												option.title == "Estrategias" ? console.log("Estrategias") : handleSelectOption(option.key);
											}}
											sx={{
												backgroundColor: isSelected ? "#4599d9" : "transparent",
												color: "#fff",
												border: `2px solid ${isSelected ? "#4599d9" : "#f74712"}`,
												borderRadius: "12px",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "0.7rem",
												cursor: "pointer",
												transition: "all 0.3s ease",
												marginBottom: "3%",
												boxShadow: isSelected ? "0 0 5px #4599d9, 0 0 10px #4599d9" : "0 0 5px #f74712, 0 0 10px #f74712",
												"&:hover": {
													backgroundColor: isSelected ? "#3d87c3" : "rgba(247, 71, 18, 0.2)",
													boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
													transform: "scale(1.05)",
												},
											}}>
											{option.title == "Estrategias" ? "Comprado" : isSelected ? "Seleccionado" : "Seleccionar"}
										</Button>
									) : (
										<Button
											onClick={() => handlePayment(option)}
											sx={{
												backgroundColor: "transparent",
												color: "#fff",
												border: "2px solid #f74712",
												borderRadius: "12px",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												fontSize: "0.7rem",
												padding: "6px 20px",
												cursor: "pointer",
												transition: "all 0.3s ease",
												textShadow: "0 0 2px #fff",
												boxShadow: "0 0 10px #f74712, 0 0 20px #f74712",
												"&:hover": {
													backgroundColor: "rgba(247, 71, 18, 0.2)",
													boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
													transform: "scale(1.05)",
												},
											}}>
											Comprar
										</Button>
									)}
								</div>
							</Grid>
						);
					})}
				</Grid>
			</Box>
			<Box
				sx={{
					width: "100%",
					mt: 8,
					borderTop: "2px solid rgba(69, 153, 217, 0.2)",
				}}
			/>

			<Box
				sx={{
					height: "55vh",
					borderRadius: "10px",
					padding: 2,
				}}>
				<Typography
					sx={{
						fontFamily: "'Orbitron', sans-serif",
						fontSize: 16,
						color: "#fff",
						textAlign: "center",
						marginBottom: 2,
						textShadow: "0 0 10px #4599d9",
					}}>
					Historial de Pagos
				</Typography>

				{isLoadingPayments ? (
					<Box sx={{ textAlign: "center", color: "#fff" }}>Cargando...</Box>
				) : (
					<Box
						sx={{
							maxHeight: "100%",
							overflowY: "auto",
							padding: "10px",
							"&::-webkit-scrollbar": {
								width: "8px",
							},
							"&::-webkit-scrollbar-track": {
								background: "rgba(0, 0, 0, 0.1)",
								borderRadius: "10px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "#4599d9",
								borderRadius: "10px",
								"&:hover": {
									background: "#357ab7",
								},
							},
						}}>
						{payments.map((payment) => (
							<Box
								key={payment.id}
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									backgroundColor: "rgba(0, 9, 34, 0.8)",
									padding: "15px 20px",
									marginBottom: "12px",
									borderRadius: "15px",
									color: "#fff",
									border: "1px solid rgba(69, 153, 217, 0.3)",
									boxShadow: "0 0 8px rgba(255, 255, 255, 0.8)",
									transition: "all 0.3s ease",
									"&:hover": {
										backgroundColor: "rgba(69, 153, 217, 0.15)",
										transform: "translateY(-2px)",
										boxShadow: "0 0 20px rgba(69, 153, 217, 0.4)",
									},
								}}>
								<Box sx={{ flex: 1 }}>
									<Typography
										sx={{
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "0.9rem",
											fontWeight: "bold",
											color: "#4599d9",
											textShadow: "0 0 5px rgba(69, 153, 217, 0.5)",
											marginBottom: "4px",
										}}>
										{payment.chartType}
									</Typography>
									<Typography
										sx={{
											fontFamily: "'Orbitron', sans-serif",
											fontSize: "0.6rem",
											color: "#f74712",
										}}>
										{new Date(payment.createdAt).toLocaleString()}
									</Typography>
								</Box>
								<Typography
									sx={{
										fontFamily: "'Orbitron', sans-serif",
										fontSize: "1.1rem",
										fontWeight: "bold",
										color: "#fff",
										textShadow: "0 0 8px rgba(69, 153, 217, 0.6)",
									}}>
									${payment.amount}
								</Typography>
							</Box>
						))}
					</Box>
				)}
			</Box>

			<Payments openPayments={showPaymentInputs} onClosePayments={() => setShowPaymentInputs(false)} service={service} price={price} />
		</div>
	);
};

export default ChartTypesPage;
