import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Stepper, Step, StepLabel } from "@mui/material";
import StorefrontOutlined from "@mui/icons-material/StorefrontOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InactiveAccountModal = ({ open, onClose, onGoToStore }) => {
	const steps = [
		{
			label: "Ir a la Tienda",
			icon: <StorefrontOutlined sx={{ color: "#4599d9" }} />,
			description: "Haz click en el botón de tienda para ver nuestros paquetes",
		},
		{
			label: "Seleccionar Paquete",
			icon: <CheckCircleIcon sx={{ color: "#4599d9" }} />,
			description: 'Recomendamos el paquete "3 en 1" para mejor experiencia',
		},
		{
			label: "Realizar Pago",
			icon: <CreditCardIcon sx={{ color: "#4599d9" }} />,
			description: "Ingresa los datos de tu tarjeta para activar tu cuenta",
		},
	];

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{
				sx: {
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					border: "1px solid #4599d9",
					boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
					borderRadius: "15px",
					minWidth: "400px",
				},
			}}>
			<DialogTitle
				sx={{
					textAlign: "center",
					color: "#fff",
					fontFamily: "'Orbitron', sans-serif",
					textShadow: "0 0 10px #4599d9",
				}}>
				Cuenta Inactiva
			</DialogTitle>

			<DialogContent>
				<Box sx={{ mb: 3 }}>
					<Typography
						sx={{
							color: "#f74712",
							textAlign: "center",
							mb: 2,
							fontFamily: "'Orbitron', sans-serif",
						}}>
						Tu cuenta necesita ser activada para usar esta función
					</Typography>

					<img
						src="/assets/3in1.png"
						alt="3 en 1 Package"
						style={{
							width: "100%",
							maxWidth: "200px",
							display: "block",
							margin: "20px auto",
							borderRadius: "10px",
							boxShadow: "0 0 15px rgba(69, 153, 217, 0.4)",
						}}
					/>
				</Box>

				<Stepper orientation="vertical" sx={{ mb: 3 }}>
					{steps.map((step, index) => (
						<Step key={step.label} active={true}>
							<StepLabel
								StepIconComponent={() => step.icon}
								sx={{
									"& .MuiStepLabel-label": {
										color: "#fff",
										fontFamily: "'Orbitron', sans-serif",
									},
								}}>
								<Typography sx={{ color: "#fff", fontFamily: "'Orbitron', sans-serif" }}>{step.label}</Typography>
								<Typography sx={{ color: "#8a8f98", fontSize: "0.8rem", fontFamily: "'Orbitron', sans-serif" }}>
									{step.description}
								</Typography>
							</StepLabel>
						</Step>
					))}
				</Stepper>
			</DialogContent>

			<DialogActions sx={{ justifyContent: "center", pb: 3 }}>
				<Button
					onClick={onGoToStore}
					sx={{
						backgroundColor: "transparent",
						color: "#fff",
						border: "2px solid #4599d9",
						borderRadius: "12px",
						fontFamily: "'Orbitron', sans-serif",
						padding: "10px 30px",
						"&:hover": {
							backgroundColor: "rgba(69, 153, 217, 0.2)",
							boxShadow: "0 0 15px #4599d9",
						},
					}}>
					Ir a la Tienda
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InactiveAccountModal;
