// src/pages/Login/LoginPage.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, CssBaseline, Box, Typography, TextField, Button, Avatar, Paper } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";
import socket from "../../../socket/Socket.js";
import { store } from "../../../store/store.js";

const theme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "#01a79e",
		},
		background: {
			default: "#121212",
			paper: "#1d1d1d",
		},
		text: {
			primary: "#ffffff",
		},
	},
});

const LoginPage = ({ setAuthenticated }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const history = useHistory();

	useEffect(() => {
		const checkAuth = async () => {
			const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
			const token = localStorage.getItem("token");
			if (selectedAccount) {
				setAuthenticated(true);
				socket.emit("login", {
					userId: selectedAccount.userId,
					account: selectedAccount.account,
					token: token,
				});
				history.push("/chart");
			}
		};

		checkAuth();
	}, [setAuthenticated, history]);

	const handleLogin = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post("https://mitserver.app:9005/login", {
				email: username,
				password,
			});

			if (response.status === 200) {
				const data = response.data.data;
				const token = response.data.token;

				localStorage.setItem("user", JSON.stringify(data));
				localStorage.setItem("token", token);
				const selectedAccount = {
					account: data.demoAcc,
					userId: data.id,
					token: data.demoToken,
				};

				localStorage.setItem("selectedAccount", JSON.stringify(selectedAccount));
				setAuthenticated(true);
				socket.emit("login", {
					userId: data.id,
					token: token,
				});
				localStorage.setItem("contractCategory", "multiplier");
				history.push("/chart");
			} else {
				toast.error(response.data.message || "Error during login");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "An error occurred. Please try again.");
		}
	};

	const handleForgotPassword = async () => {
		try {
			if (username === "") {
				toast.error("Por favor escribe tu correo electrónico");
				return;
			}
			const response = await axios.post("https://mitserver.app:9005/forgot", {
				email: username,
			});
			if (response.status === 200) {
				toast.success(response.data.message || "Contraseña Enviada");
			} else {
				toast.error(response.data.message || "Error al enviar correo");
			}
		} catch (error) {
			toast.error(error.response?.data?.message || "Un error ha ocurrido. Por favor intenta de nuevo.");
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					backgroundImage: 'url("/assets/bg.jpg")',
					backgroundSize: "cover",
					backgroundPosition: "center",
					minHeight: "100vh",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<CssBaseline />
				<Container component="main" maxWidth="xs" sx={{ marginLeft: "40%" }}>
					<Paper
						elevation={6}
						style={{
							padding: "20px",
							borderRadius: "10px",
							background: "transparent",
							// boxShadow: "0 0 20px #4599d9, 0 0 40px #4599d9",
						}}>
						<Box
							sx={{
								marginTop: 8,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<Box sx={{ m: 1 }}>
								<img src="/assets/logoMV.png" alt="Login" width="100%" />
							</Box>
							<Typography component="h1" variant="h5" sx={{ color: "#fff", textShadow: "0 0 10px #4599d9", marginTop: "60px" }}>
								Inicio de Sesión
							</Typography>
							<Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="username"
									label="E-mail"
									name="username"
									autoComplete="username"
									autoFocus
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{
										style: { color: "#ffffff" },
										sx: {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#f74712" },
										},
									}}
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Contraseña"
									type="password"
									id="password"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									InputLabelProps={{ style: { color: "#ffffff" } }}
									InputProps={{
										style: { color: "#ffffff" },
										sx: {
											"& fieldset": { borderColor: "#4599d9" },
											"&:hover fieldset": { borderColor: "#f74712" },
											"&.Mui-focused fieldset": { borderColor: "#f74712" },
										},
									}}
								/>

								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 3,
										mb: 2,
										backgroundColor: "#4599d9",
										color: "#fff",
										"&:hover": {
											backgroundColor: "#3d87c3",
										},
										boxShadow: "0 0 5px #4599d9, 0 0 5px #4599d9",
									}}>
									Iniciar
								</Button>
								<Typography align="center">
									<Link to="#" onClick={handleForgotPassword} style={{ color: "#f74712", textShadow: "0 0 5px#cc2e3d" }}>
										¿Olvidaste tu contraseña?
									</Link>
								</Typography>
								<Button
									fullWidth
									variant="contained"
									sx={{
										mt: 2,
										mb: 2,
										backgroundColor: "#833ab4",
										color: "#fff",
										"&:hover": {
											backgroundColor: "#833ab4",
										},
										boxShadow: "0 0 5px #833ab4, 0 0 5px #833ab4",
									}}
									onClick={() => history.push("/register")}>
									Registrarse
								</Button>
							</Box>
						</Box>
					</Paper>
				</Container>
				<Box sx={{ m: 1 }}>
					<img src="/assets/ticksy3.png" alt="Login" width="100%" />
				</Box>
			</Box>
		</ThemeProvider>
	);
};

export default LoginPage;
