import { Commit } from "@mui/icons-material";
import { image } from "@tensorflow/tfjs";
import { create } from "zustand";

const initialState = {
	lastBar: null,
	currentBar: null,
	selectedAccount: null,
	symbolInfo: null,
	derivWS: null,
	balance: null,
	chart: null,
	version: "V1.0.10",
	symbols: null,
	openPositions: null,
	customCandles: null,
	tickCount: null,
	proposalId: null,
	optionType: null,
	image: null,
	accounts: null,
	positionsData: [],
	chartInfo: null,
	tradeAmount: null,
	slPrice: null,
	tpPrice: null,
	multipliers: [],
	multiplier: null,
	commission: null,
	slTpButton: false,
	retCandles: false,
	userActive: false,
	trailingStop: false,
};

const apiStore = (set, get) => ({
	...initialState,
	setLastBar: (lastBar) => set({ lastBar }),
	setCurrentBar: (currentBar) => set({ currentBar }),
	setSelectedAccount: (selectedAccount) => set({ selectedAccount }),
	setSymbolInfo: (symbolInfo) => set({ symbolInfo }),
	setDerivWS: (derivWS) => set({ derivWS }),
	setBalance: (balance) => set({ balance }),
	setChart: (chart) => set({ chart }),
	setVersion: (version) => set({ version }),
	setSymbols: (symbols) => set({ symbols }),
	setOpenPositions: (openPositions) => set({ openPositions }),
	setCustomCandles: (customCandles) => set({ customCandles }),
	setTickCount: (tickCount) => set({ tickCount }),
	setProposalId: (proposalId) => set({ proposalId }),
	setOptionType: (optionType) => set({ optionType }),
	setImage: (image) => set({ image }),
	setAccount: (account) => set({ account }),
	setPositionsData: (positionsData) => set({ positionsData }),
	setChartInfo: (chartInfo) => set({ chartInfo }),
	setTradeAmount: (tradeAmount) => set({ tradeAmount }),
	setSlPrice: (slPrice) => set({ slPrice }),
	setTpPrice: (tpPrice) => set({ tpPrice }),
	setMultipliers: (multipliers) => set({ multipliers }),
	setMultiplier: (multiplier) => set({ multiplier }),
	setCommission: (commission) => set({ commission }),
	setSlTpButton: (slTpButton) => set({ slTpButton }),
	setRetCandles: (retCandles) => set({ retCandles }),
	setUserActive: (userActive) => set({ userActive }),
	setTrailingStop: (trailingStop) => set({ trailingStop }),
	updateOpenPositions: (position) =>
		set((state) => {
			const existingIndex = state.positionsData.findIndex((pos) => pos.contract_id === position.contract_id);

			if (existingIndex !== -1) {
				state.positionsData[existingIndex] = position;
			} else {
				state.positionsData.push(position);
			}

			return { positionsData: [...state.positionsData] };
		}),
	removeOpenPosition: (contractId) =>
		set((state) => ({
			positionsData: state.positionsData.filter((pos) => pos.contract_id !== contractId),
		})),
	removeOpenPositionBySubscriptionId: (subscriptionId) =>
		set((state) => ({
			positionsData: state.positionsData.filter((pos) => pos.subscription_id !== subscriptionId),
		})),
	clearOpenPositions: () =>
		set(() => ({
			positionsData: [],
		})),
	reset: () => set(initialState),
});

export const store = create(apiStore);
