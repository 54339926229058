import React from "react";
import { Box } from "@mui/material";

export const SlidingPanel = ({ isOpen, children }) => {
	return (
		isOpen && (
			<Box
				sx={{
					width: "20%",
					padding: "2px",
					display: "flex",
					flexDirection: "column",
					background: "linear-gradient(to bottom, #000000, #000033)",
					height: "94%",
					paddingLeft: "5px",
					marginTop: "40px",
					marginLeft: "5px",
				}}>
				{children}
			</Box>
		)
	);
};
