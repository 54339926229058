import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
	Star as StarIcon,
	TrendingUp as TrendingUpIcon,
	TrendingDown as TrendingDownIcon,
	Send as SendIcon,
	Instagram as InstagramIcon,
	Telegram as TelegramIcon,
} from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";

export function Ticksy({ onClose }) {
	const [posts, setPosts] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [isTrendUpActive, setIsTrendUpActive] = useState(false);
	const [isStarActive, setIsStarActive] = useState(false);
	const [isTrendDownActive, setIsTrendDownActive] = useState(false);

	const fetchPosts = async () => {
		const maxRetries = 5;
		let retryCount = 0;
		let retryDelay = 2000;

		const attemptFetch = async () => {
			setLoading(true);
			try {
				const response = await axios.post("https://mitserver.app:9005/getFeed", {
					page: page,
					limit: 10,
				});
				const newPosts = response.data.posts;
				setPosts((prevPosts) => {
					const existingPostIds = new Set(prevPosts.map((post) => post.id));
					const filteredNewPosts = newPosts.filter((post) => !existingPostIds.has(post.id));
					return [...prevPosts, ...filteredNewPosts].reverse();
				});
				setLoading(false);
			} catch (error) {
				console.log("Fetch error:", error);
				if (retryCount < maxRetries) {
					retryCount++;
					retryDelay *= 1.5;
					setTimeout(attemptFetch, retryDelay);
				} else {
					setLoading(false);
					setPosts([]);
				}
			}
		};

		attemptFetch();
	};

	useEffect(() => {
		fetchPosts();
	}, [page]);

	const handleScroll = () => {
		if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
			return;
		}
		setPage((prevPage) => prevPage + 1);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [loading]);

	const formatDate = (dateString) => {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
			hour12: false,
		};
		return new Date(dateString).toLocaleString(undefined, options);
	};

	const handleTrendUp = () => {
		setIsTrendUpActive(!isTrendUpActive);
	};

	const handleStar = () => {
		setIsStarActive(!isStarActive);
	};

	const handleTrendDown = () => {
		setIsTrendDownActive(!isTrendDownActive);
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "linear-gradient(to bottom, #000000, #000033)",
				display: "flex",
				flexDirection: "column",
			}}>
			<Typography
				variant="h5"
				sx={{
					color: "transparent",
					backgroundImage: "linear-gradient(45deg,#cc2e3d,#cc2e3d, #62fee1, #4599d9)",
					backgroundClip: "text",
					WebkitBackgroundClip: "text",
					fontFamily: "'Orbitron', sans-serif",
					textAlign: "center",
					fontWeight: "bold",
					padding: "10px 0",
					borderBottom: "1px solid rgba(255,255,255,0.1)",
				}}>
				TICKSY SOCIAL
			</Typography>

			<Box
				sx={{
					flex: 1,
					overflow: "auto",
					padding: "10px",
					"&::-webkit-scrollbar": {
						width: "6px",
					},
					"&::-webkit-scrollbar-track": {
						background: "#1a1f2d",
					},
					"&::-webkit-scrollbar-thumb": {
						background: "#4599d9",
						borderRadius: "3px",
					},
				}}>
				{posts.map((post) => (
					<Box
						key={post.id}
						sx={{
							mb: 3,
							backgroundColor: "#1a1f2d",
							borderRadius: "12px",
							overflow: "hidden",
							boxShadow: "0 2px 12px rgba(0,0,0,0.1)",
						}}>
						{/* Header */}
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								p: 1.5,
								borderBottom: "1px solid rgba(255,255,255,0.1)",
							}}>
							<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
								<Box
									sx={{
										width: 32,
										height: 32,
										borderRadius: "50%",
										background: "linear-gradient(45deg,#cc2e3d, #4599d9)",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										color: "#fff",
										fontWeight: "bold",
										fontSize: "0.8rem",
									}}>
									{post.author[0].toUpperCase()}
								</Box>
								<Box>
									<Typography
										sx={{
											color: "#fff",
											fontSize: "0.9rem",
											fontWeight: "500",
											fontFamily: "'Orbitron', sans-serif",
										}}>
										{post.author}
									</Typography>
									<Typography
										sx={{
											color: "rgba(255,255,255,0.6)",
											fontSize: "0.7rem",
										}}>
										{post.symbol}
									</Typography>
								</Box>
							</Box>
							<Typography
								sx={{
									color: "#4599d9",
									fontSize: "0.7rem",
								}}>
								{formatDate(post.date)}
							</Typography>
						</Box>

						{/* Image */}
						<Box sx={{ position: "relative" }}>
							<img
								src={post.image}
								alt="Trading Analysis"
								onClick={() => setSelectedImage(post.image)}
								style={{
									width: "100%",
									display: "block",
									cursor: "pointer",
								}}
							/>
						</Box>

						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Box sx={{ display: "flex", gap: 0.2, p: 0.5, alignItems: "flex-start" }}>
								<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<IconButton
										size="small"
										sx={{
											color: isTrendUpActive ? "#4599d9" : "#fff",
											p: 0.2,
											"&:hover": { color: "#4599d9" },
										}}
										onClick={handleTrendUp}>
										<TrendingUpIcon sx={{ fontSize: "1rem" }} />
									</IconButton>
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<IconButton
										size="small"
										sx={{
											color: isStarActive ? "red" : "#fff",
											p: 0.2,
											"&:hover": { color: "red" },
										}}
										onClick={handleStar}>
										<StarIcon sx={{ fontSize: "1rem" }} />
									</IconButton>
								</Box>
								<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
									<IconButton
										size="small"
										sx={{
											color: isTrendDownActive ? "#f74712" : "#fff",
											p: 0.2,
											"&:hover": { color: "#f74712" },
										}}
										onClick={handleTrendDown}>
										<TrendingDownIcon sx={{ fontSize: "1rem" }} />
									</IconButton>
								</Box>
							</Box>
							<Typography
								sx={{
									fontSize: "0.6rem",
									color: "#fff",
									mt: 0.5,
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{post.stars || 0} TrendsUp
							</Typography>
						</Box>

						{/* Content */}
						<Box sx={{ p: 1.5 }}>
							<Typography
								sx={{
									color: "#fff",
									fontSize: "1rem",
									fontWeight: "500",
									mb: 1,
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{post.title}
							</Typography>
							<Typography
								sx={{
									color: "rgba(255,255,255,0.8)",
									fontSize: "0.9rem",
									mb: 2,
								}}>
								{post.description}
							</Typography>

							<Box
								sx={{
									display: "flex",
									gap: 2,
									borderTop: "1px solid rgba(255,255,255,0.1)",
									pt: 1.5,
								}}>
								<Box
									component="a"
									href={`https://instagram.com/${post.instagram}`}
									target="_blank"
									rel="noopener noreferrer"
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#f74712",
										textDecoration: "none",
										"&:hover": { opacity: 0.8 },
									}}>
									<InstagramIcon sx={{ fontSize: "1.2rem" }} />
								</Box>
								<Box
									component="a"
									href={`https://t.me/${post.telegram}`}
									target="_blank"
									rel="noopener noreferrer"
									sx={{
										display: "flex",
										alignItems: "center",
										color: "#4599d9",
										textDecoration: "none",
										"&:hover": { opacity: 0.8 },
									}}>
									<TelegramIcon sx={{ fontSize: "1.2rem" }} />
								</Box>
							</Box>
						</Box>
					</Box>
				))}
				{loading && (
					<Box sx={{ textAlign: "center", py: 2 }}>
						<CircularProgress size={24} sx={{ color: "#4599d9" }} />
					</Box>
				)}
			</Box>

			<Modal
				open={!!selectedImage}
				onClose={() => setSelectedImage(null)}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<img
					src={selectedImage}
					style={{
						maxWidth: "90vw",
						maxHeight: "90vh",
						borderRadius: "8px",
						boxShadow: "0 0 20px rgba(69, 153, 217, 0.5)",
					}}
				/>
			</Modal>
		</Box>
	);
}
