import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import { store } from "../../../../store/store";
import { toast } from "react-toastify";
import axios from "axios";

export function AddToTicksy({ onClose }) {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const user = JSON.parse(localStorage.getItem("user"));
	const [symbol, setSymbol] = useState(null);
	const image = useRef(null);

	useEffect(() => {
		async function callFirst() {
			image.current = store.getState().image;
			addWatermark(image.current).then((watermarkedImage) => {
				image.current = watermarkedImage;
			});
			const symbolInfo = await localStorage.getItem("symbolInfo");
			if (symbolInfo) {
				const parsedSymbolInfo = JSON.parse(symbolInfo);
				setSymbol(parsedSymbolInfo.displayName);
			}
		}
		callFirst();
	}, []);

	const addWatermark = (base64Image) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.src = base64Image;

			img.onload = () => {
				const canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;

				const ctx = canvas.getContext("2d");

				// Draw original image
				ctx.drawImage(img, 0, 0);

				// Add watermark
				const logo = new Image();
				logo.src = "/assets/logoMV.png "; // Your logo path

				logo.onload = () => {
					// Position watermark in bottom right corner
					const watermarkWidth = img.width * 0.2; // 20% of image width
					const ratio = logo.height / logo.width;
					const watermarkHeight = watermarkWidth * ratio;

					ctx.globalAlpha = 0.5; // Transparency
					ctx.drawImage(
						logo,
						img.width - watermarkWidth - 120, // 20px padding from right
						img.height - watermarkHeight - 70, // 20px padding from bottom
						watermarkWidth,
						watermarkHeight
					);

					resolve(canvas.toDataURL());
				};
			};
		});
	};

	const handleSubmit = async () => {
		try {
			const post = {
				userId: user.id,
				symbol: symbol,
				author: user.name,
				date: new Date().toISOString(),
				image: image.current,
				title: title,
				description: description,
				instagram: user.instagram || "",
				telegram: user.telegram || "",
			};

			const response = await axios.post("https://mitserver.app:9005/savePost", post);
			if (response.data.ok) {
				toast.success(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				onClose();
			} else {
				toast.error(response.data.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			}
		} catch (error) {
			console.error("Error creating post:", error);
			toast.error("Error creating post");
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "linear-gradient(to bottom, #000000, #000033)",
				padding: 2,
				display: "flex",
				flexDirection: "column",
				gap: 2,
			}}>
			<Typography
				variant="h5"
				sx={{
					color: "transparent",
					backgroundImage: "linear-gradient(45deg,#cc2e3d,#cc2e3d, #62fee1, #4599d9)",
					backgroundClip: "text",
					WebkitBackgroundClip: "text",
					fontFamily: "'Orbitron', sans-serif",
					textAlign: "center",
					fontWeight: "bold",
				}}>
				TICKSY POST
			</Typography>

			<TextField
				fullWidth
				label="Title"
				value={title}
				onChange={(e) => setTitle(e.target.value)}
				size="small"
				sx={{
					"& .MuiOutlinedInput-root": {
						color: "#fff",
						backgroundColor: "#2a2e39",
					},
				}}
			/>

			<TextField
				fullWidth
				multiline
				rows={3}
				label="Description"
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				size="small"
				sx={{
					"& .MuiOutlinedInput-root": {
						color: "#fff",
						backgroundColor: "#2a2e39",
					},
				}}
			/>

			<Button
				fullWidth
				variant="contained"
				onClick={handleSubmit}
				sx={{
					backgroundColor: "#4599d9",
					fontFamily: "'Orbitron', sans-serif",
					fontWeight: 700,
					fontSize: "0.9rem",
					boxShadow: "0 0 10px #4599d9",
					color: "#fff",
					"&:hover": {
						backgroundColor: "#357ab7",
						boxShadow: "0 0 20px #4599d9",
					},
				}}>
				Post Analisis
			</Button>

			<Box sx={{ flex: 1, overflow: "auto" }}>
				<Box
					sx={{
						p: 2,
						borderRadius: 2,
						backgroundColor: "#2a2e39",
						boxShadow: "0 0 5px #4599d9",
					}}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 1,
						}}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								mb: 1,
							}}>
							<Typography
								sx={{
									color: "#fff",
									fontFamily: "'Orbitron', sans-serif",
									fontSize: "0.9rem",
								}}>
								{user.name}
							</Typography>
							<Typography
								sx={{
									color: "rgba(255,255,255,0.7)",
									fontFamily: "'Orbitron', sans-serif",
									fontSize: "0.7rem",
								}}>
								{symbol}
							</Typography>
						</Box>

						<Typography
							sx={{
								color: "#4599d9",
								fontSize: "0.8rem",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							{new Date().toISOString().split("T")[0]}
						</Typography>
					</Box>

					<img
						src={image.current}
						alt="Trading Analysis Preview"
						style={{
							width: "100%",
							borderRadius: "8px",
							marginBottom: "8px",
						}}
					/>

					<Typography
						sx={{
							color: "#fff",
							fontFamily: "'Orbitron', sans-serif",
							fontSize: "0.9rem",
							mb: 1,
						}}>
						{title}
					</Typography>

					<Typography
						sx={{
							color: "#fff",
							fontSize: "0.8rem",
							mb: 1,
						}}>
						{description}
					</Typography>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 1,
						}}>
						<Box component="a" href={`${user.instagram}`} target="_blank" rel="noopener noreferrer">
							<InstagramIcon
								sx={{
									fontSize: "1.2rem",
									color: "#f74712",
									cursor: "pointer",
									"&:hover": { opacity: 0.8 },
								}}
							/>
						</Box>
						<Box component="a" href={`${user.telegram}`} target="_blank" rel="noopener noreferrer">
							<TelegramIcon
								sx={{
									fontSize: "1.2rem",
									color: "#4599d9",
									cursor: "pointer",
									"&:hover": { opacity: 0.8 },
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
