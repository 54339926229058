import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, TextField, Button, CircularProgress } from "@mui/material";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { toast } from "react-toastify";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";
import JSConfetti from "js-confetti";
import { gsap } from "gsap";
import { set } from "lodash";

const jsConfetti = new JSConfetti();

const Payments = ({ openPayments, onClosePayments, service, price, buyTo }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const [errors, setErrors] = useState({});
	const [paymentMethod, setPaymentMethod] = useState("Tarjeta");
	const [cardDetails, setCardDetails] = useState({
		number: "",
		name: "",
		expiry: "",
		cvc: "",
		focused: "",
	});
	const [cryptoPaymentInfo, setCryptoPaymentInfo] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (openPayments) {
			console.log("service:", service);
			console.log("price:", price);
		}
	}, [openPayments]);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		console.log("handleInputChange", e.target.name, e.target.value);
		setCardDetails((prevDetails) => ({
			...prevDetails,
			[name]: value,
		}));
	};

	const handleInputFocus = (e) => {
		setCardDetails((prevDetails) => ({
			...prevDetails,
			focused: e.target.name,
		}));
	};

	const handlePaymentMethodChange = (method) => {
		console.log("CHOOSE PAYMENT METHOD", method);
		setPaymentMethod(method);
		if (method === "Crypto") {
			handleCryptoPayment();
		}
	};

	const validateCardDetails = () => {
		console.log("VALIDATE CARD DETAILS");

		let tempErrors = {};

		// Validate card number (basic check for length and numeric)
		if (!cardDetails.number || cardDetails.number.length < 13 || cardDetails.number.length > 19 || !/^\d+$/.test(cardDetails.number)) {
			tempErrors.number = "Número de tarjeta inválido";
		}

		// Validate name (minimum 5 characters)
		if (!cardDetails.name || cardDetails.name.length < 5) {
			tempErrors.name = "El nombre debe tener al menos 5 caracteres";
		}

		// Validate expiry date (MM/YY format)
		if (!cardDetails.expiry || !/^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(cardDetails.expiry)) {
			tempErrors.expiry = "Fecha de expiración inválida (formato: MM/YY)";
			return;
		}
		const [month, year] = cardDetails.expiry.split("/");
		const currentYear = new Date().getFullYear() % 100; // Get last two digits of current year
		const currentMonth = new Date().getMonth() + 1;

		if (parseInt(year) < currentYear || (parseInt(year) === currentYear && parseInt(month) < currentMonth)) {
			tempErrors.expiry = "Fecha de expiración inválida";
		}
		// Validate CVV (3 or 4 digits)
		if (!cardDetails.cvc || !/^\d{3,4}$/.test(cardDetails.cvc)) {
			tempErrors.cvc = "CVV inválido";
		}

		console.log("ERRORS", tempErrors);

		setErrors(tempErrors);
		return Object.keys(tempErrors).length === 0;
	};

	const handleSubmitPayment = () => {
		if (!validateCardDetails()) {
			toast.error("Algunos de los campo estan con errores", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				theme: "dark",
			});
			return;
		}
		setLoading(true);
		const payload = {
			userId: user.id,
			amount: price,
			chartType: service,
			cardDetails: cardDetails,
			buyTo: buyTo,
		};
		axios
			.post("https://mitserver.app:9005/purchase", payload)
			.then((response) => {
				if (response.data.ok) {
					toast.success(response.data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					setLoading(false);
					onClosePayments();
					showEpicWelcome();
					setTimeout(() => {
						window.location.reload();
					}, 5000);
				} else {
					setLoading(false);

					toast.error(response.data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
					onClosePayments();
				}
				handleSearch();
			})
			.catch((error) => {});
	};

	const handleCryptoPayment = async () => {
		const maxRetries = 3;
		let retryCount = 0;

		const attemptLogin = async () => {
			try {
				setLoading(true);
				const user = JSON.parse(localStorage.getItem("user"));

				const payload = {
					userId: "3",
					customerId: user.id,
					buyTo: buyTo,
					service: "drawings",
					description: "Mensualidad Drawings",
					amount: price,
					currency: "USDT TRC20",
					frequency: "Mensual",
					orderId: `ORD3-${Date.now()}-${user.id}`,
				};
				axios
					.post("https://mitserver.app:9005/purchaseCrypto", payload)
					.then((response) => {
						console.log("RESPONSE CHARGE CRYPTO", response.data);

						if (response.data.ok) {
							setCryptoPaymentInfo(response.data.data);
							setLoading(false);
						} else {
							toast.error(response.data.message, {
								position: "top-right",
								autoClose: 3000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								theme: "dark",
							});
							setLoading(false);
						}
					})
					.catch((error) => {});
			} catch (error) {
				if (retryCount < maxRetries && error.message.includes("network")) {
					retryCount++;
					await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2s between retries
					return attemptLogin();
				}

				const errorMessage = error.response?.data?.details?.message || "Error processing payment";
				toast.error(errorMessage, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				setLoading(false);
			}
		};

		attemptLogin();
	};

	async function createOrder(userId, amount) {
		const response = await fetch("/generateAddress", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				orderId: `order-${Date.now()}`, // Generar un ID único
				userId,
				amount,
				currency: "USDT",
			}),
		});

		const data = await response.json();

		if (response.ok) {
			console.log("Orden creada:", data);
			return data; // { message, orderId, address }
		} else {
			console.error("Error al crear la orden:", data.error);
		}
	}

	async function checkOrderStatus(orderId) {
		const response = await fetch("/getAddress", {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ orderId }),
		});

		const data = await response.json();

		if (response.ok) {
			console.log("Estado de la orden:", data);
			return data; // { message, orderId, address, amount, status }
		} else {
			console.error("Error al consultar la orden:", data.error);
		}
	}

	function showEpicWelcome() {
		// Confeti dinámico
		jsConfetti.addConfetti({
			emojis: ["🎉", "🎊", "🔥", "🏆", "💎"],
			emojiSize: 50,
			confettiNumber: 100,
		});

		// Contenedor para el mensaje
		const animationContainer = document.createElement("div");
		animationContainer.style.position = "fixed";
		animationContainer.style.top = "50%";
		animationContainer.style.left = "50%";
		animationContainer.style.transform = "translate(-50%, -50%)";
		animationContainer.style.zIndex = "9999";
		animationContainer.style.textAlign = "center";
		animationContainer.style.color = "#FFF";
		animationContainer.style.fontFamily = "'Poppins', sans-serif";
		animationContainer.style.fontWeight = "bold";

		// Título principal
		const title = document.createElement("h1");
		title.textContent = "¡Bienvenido a nuestro Club!";
		title.style.fontSize = "4rem";
		title.style.textShadow = "0 0 20px #FFD700, 0 0 40px #FF4500";

		// Subtítulo
		const subtitle = document.createElement("p");
		subtitle.textContent = "¡Prepárate para una experiencia única en trading!";
		subtitle.style.fontSize = "1.5rem";
		subtitle.style.marginTop = "10px";

		animationContainer.appendChild(title);
		animationContainer.appendChild(subtitle);
		document.body.appendChild(animationContainer);

		// Animaciones con GSAP
		gsap.fromTo(
			animationContainer,
			{ opacity: 0, scale: 0 },
			{
				opacity: 1,
				scale: 1,
				duration: 1.5,
				ease: "elastic.out(1, 0.3)",
			}
		);

		gsap.to(animationContainer, {
			opacity: 0,
			scale: 0.5,
			duration: 1,
			delay: 4,
			ease: "power2.in",
			onComplete: () => animationContainer.remove(),
		});
	}

	const style = document.createElement("style");
	style.textContent = `
		@keyframes explode {
			0% {
				transform: scale(1);
				opacity: 1;
			}
			100% {
				transform: scale(3);
				opacity: 0;
			}
		}
	`;
	document.head.appendChild(style);

	return (
		<>
			{openPayments && (
				<>
					<Box
						onClick={onClosePayments}
						sx={{
							position: "fixed",
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							zIndex: 9998,
						}}>
						<Box
							onClick={(e) => e.stopPropagation()}
							sx={{
								position: "fixed",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 9999,
								marginTop: 4,
								padding: 2,
								backgroundColor: "#1c2331",
								borderRadius: "20px",
								boxShadow: "0 0 15px#cc2e3d, 0 0 10px#cc2e3d",
								maxWidth: "500px",
								width: "90%",
								maxHeight: "90vh",
								overflowY: "auto",
							}}>
							<Typography
								sx={{ fontFamily: "'Orbitron', sans-serif", fontSize: 14, p: 1, textAlign: "center" }}
								color="text.secondary"
								gutterBottom>
								Suscripción Mensual de <span style={{ fontWeight: "bold", color: "white" }}>{service}</span> por $
								<span style={{ fontSize: 14, fontWeight: "bold", color: "white" }}>{price}</span>
							</Typography>

							<Typography
								sx={{ fontFamily: "'Orbitron', sans-serif", fontSize: 12, p: 1, textAlign: "center" }}
								color="text.secondary"
								gutterBottom>
								Después de realizar la transferencia, espere aproximadamente 3 minutos y recargue la página para ver reflejada su
								compra.
							</Typography>

							<Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
								<Button
									variant={paymentMethod === "Tarjeta" ? "contained" : "outlined"}
									onClick={() => handlePaymentMethodChange("Tarjeta")}
									sx={{
										mx: 1,
										backgroundColor: paymentMethod === "Tarjeta" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										"&:hover": {
											backgroundColor: paymentMethod === "Tarjeta" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										},
									}}>
									Tarjeta
								</Button>
								<Button
									variant={paymentMethod === "Crypto" ? "contained" : "outlined"}
									onClick={() => handlePaymentMethodChange("Crypto")}
									sx={{
										mx: 1,
										backgroundColor: paymentMethod === "Crypto" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										"&:hover": {
											backgroundColor: paymentMethod === "Crypto" ? "#f74712" : "rgba(247, 71, 18, 0.5)",
										},
									}}>
									Crypto
								</Button>
							</Box>
							{paymentMethod === "Tarjeta" && (
								<>
									<Cards
										number={cardDetails.number}
										name={cardDetails.name}
										expiry={cardDetails.expiry}
										cvc={cardDetails.cvc}
										focused={cardDetails.focused}
									/>
									<TextField
										label="Número de Tarjeta"
										variant="outlined"
										margin="dense"
										name="number"
										value={cardDetails.number}
										onChange={handleInputChange}
										onFocus={handleInputFocus}
										error={!!errors.number}
										helperText={errors.number}
										sx={{
											width: "100%",
											mb: 1,
											"& .MuiOutlinedInput-root": {
												transition: "all 0.2s ease-in-out",
												"& fieldset": {
													borderColor: "rgba(255, 255, 255, 0.23)",
												},
												"&:hover fieldset": {
													borderColor: "#4599d9",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#4599d9",
												},
											},
											"& .MuiInputBase-input": {
												color: "#fff",
												padding: "14px",
											},
											"& .MuiInputLabel-root": {
												color: "rgba(255, 255, 255, 0.7)",
												"&.Mui-focused": {
													color: "#4599d9",
												},
												transform: "translate(14px, 16px) scale(1)",
												"&.Mui-focused, &.MuiFormLabel-filled": {
													transform: "translate(14px, -9px) scale(0.75)",
												},
											},
										}}
										InputProps={{
											style: { color: "#fff", fontSize: "1rem" },
										}}
										InputLabelProps={{
											style: { color: "#fff", fontSize: "0.9rem" },
										}}
									/>
									<TextField
										label="Nombre en la Tarjeta"
										variant="outlined"
										margin="dense"
										name="name"
										value={cardDetails.name}
										onChange={handleInputChange}
										onFocus={handleInputFocus}
										error={!!errors.name}
										helperText={errors.name}
										sx={{
											width: "100%",
											mb: 1,
											"& .MuiOutlinedInput-root": {
												transition: "all 0.2s ease-in-out",
												"& fieldset": {
													borderColor: "rgba(255, 255, 255, 0.23)",
												},
												"&:hover fieldset": {
													borderColor: "#4599d9",
												},
												"&.Mui-focused fieldset": {
													borderColor: "#4599d9",
												},
											},
											"& .MuiInputBase-input": {
												color: "#fff",
												padding: "14px",
											},
											"& .MuiInputLabel-root": {
												color: "rgba(255, 255, 255, 0.7)",
												"&.Mui-focused": {
													color: "#4599d9",
												},
												transform: "translate(14px, 16px) scale(1)",
												"&.Mui-focused, &.MuiFormLabel-filled": {
													transform: "translate(14px, -9px) scale(0.75)",
												},
											},
										}}
										InputProps={{
											style: { color: "#fff", fontSize: "1rem" },
										}}
										InputLabelProps={{
											style: { color: "#fff", fontSize: "0.9rem" },
										}}
									/>
									<Box sx={{ display: "flex", gap: 2 }}>
										<TextField
											label="Fecha de Expiración"
											variant="outlined"
											margin="dense"
											name="expiry"
											value={cardDetails.expiry}
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											error={!!errors.expiry}
											helperText={errors.expiry || "MM/YY"}
											placeholder="MM/YY"
											inputProps={{
												maxLength: 5,
												pattern: "\\d{2}/\\d{2}",
											}}
											sx={{
												width: "80%",
												mb: 1,
												"& .MuiOutlinedInput-root": {
													transition: "all 0.2s ease-in-out",
													"& fieldset": {
														borderColor: "rgba(255, 255, 255, 0.23)",
													},
													"&:hover fieldset": {
														borderColor: "#4599d9",
													},
													"&.Mui-focused fieldset": {
														borderColor: "#4599d9",
													},
												},
												"& .MuiInputBase-input": {
													color: "#fff",
													padding: "14px",
												},
												"& .MuiInputLabel-root": {
													color: "rgba(255, 255, 255, 0.7)",
													"&.Mui-focused": {
														color: "#4599d9",
													},
													transform: "translate(14px, 16px) scale(1)",
													"&.Mui-focused, &.MuiFormLabel-filled": {
														transform: "translate(14px, -9px) scale(0.75)",
													},
												},
											}}
											InputProps={{
												style: { color: "#fff", fontSize: "1rem" },
											}}
											InputLabelProps={{
												style: { color: "#fff", fontSize: "0.9rem" },
											}}
										/>
										<TextField
											label="CVV"
											variant="outlined"
											margin="dense"
											name="cvc"
											value={cardDetails.cvc}
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											error={!!errors.cvc}
											helperText={errors.cvc}
											sx={{
												width: "80%",
												mb: 1,
												"& .MuiOutlinedInput-root": {
													transition: "all 0.2s ease-in-out",
													"& fieldset": {
														borderColor: "rgba(255, 255, 255, 0.23)",
													},
													"&:hover fieldset": {
														borderColor: "#4599d9",
													},
													"&.Mui-focused fieldset": {
														borderColor: "#4599d9",
													},
												},
												"& .MuiInputBase-input": {
													color: "#fff",
													padding: "14px",
												},
												"& .MuiInputLabel-root": {
													color: "rgba(255, 255, 255, 0.7)",
													"&.Mui-focused": {
														color: "#4599d9",
													},
													transform: "translate(14px, 16px) scale(1)",
													"&.Mui-focused, &.MuiFormLabel-filled": {
														transform: "translate(14px, -9px) scale(0.75)",
													},
												},
											}}
											InputProps={{
												style: { color: "#fff", fontSize: "1rem" },
											}}
											InputLabelProps={{
												style: { color: "#fff", fontSize: "0.9rem" },
											}}
										/>
									</Box>
									<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
										<Button
											variant="contained"
											color="primary"
											onClick={handleSubmitPayment}
											sx={{
												marginTop: 2,
												backgroundColor: "#4599d9",
												width: "150px",
												color: "#fff",
												fontWeight: "bold",
												fontFamily: "'Orbitron', sans-serif",
												padding: "8px 16px",
												fontSize: "0.8rem",
												cursor: "pointer",
												transition: "all 0.3s ease",
												textShadow: "0 0 10px #fff",
												boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												"&:hover": {
													backgroundColor: "#3d87c3",
													boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
												},
											}}>
											Pagar
										</Button>
									</Box>
								</>
							)}
							{loading ? (
								<Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
									<CircularProgress
										sx={{
											color: "#4599d9",
											"& .MuiCircularProgress-svg": {
												filter: "drop-shadow(0 0 5px #4599d9)",
											},
											width: "40px !important",
											height: "40px !important",
										}}
									/>
								</Box>
							) : paymentMethod === "Crypto" && cryptoPaymentInfo ? (
								<Box sx={{ textAlign: "center", mt: 2 }}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											mb: 2,
											boxShadow: "0 0 10px #4599d9",
											padding: "10px",
											borderRadius: "8px",
										}}>
										<img src="/assets/mPayLogo.png" alt="MPay Logo" style={{ height: "40px", marginRight: "10px" }} />
										<Typography variant="h5" sx={{ color: "#fff", fontFamily: "'Orbitron', sans-serif" }}>
											MPay Crypto
										</Typography>
									</Box>

									<Typography sx={{ color: "#fff", mb: 1 }}>
										Enviar {cryptoPaymentInfo.amount} {cryptoPaymentInfo.currency} a la dirección:
									</Typography>
									<Typography sx={{ color: "#fff", mb: 2, wordBreak: "break-all" }}>{cryptoPaymentInfo.address}</Typography>
									<QRCodeCanvas value={cryptoPaymentInfo.address} size={256} />

									{/* Additional Information */}
									<Typography sx={{ color: "#fff", mt: 2 }}>ID del Pedido: {cryptoPaymentInfo.orderId}</Typography>
									<Typography sx={{ color: "#fff" }}>Descripción: {cryptoPaymentInfo.description}</Typography>
									<Typography sx={{ color: "#fff" }}>Frecuencia: {cryptoPaymentInfo.frequency}</Typography>
									<Typography sx={{ color: "#fff" }}>Estado: {cryptoPaymentInfo.message}</Typography>
								</Box>
							) : null}
							<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
								<Button
									variant="contained"
									onClick={onClosePayments}
									sx={{
										mt: 2,
										width: "150px",
										backgroundColor: "#f74712",
										color: "#fff",
										fontWeight: "bold",
										fontFamily: "'Orbitron', sans-serif",
										padding: "8px 16px",
										fontSize: "0.8rem",
										cursor: "pointer",
										transition: "all 0.3s ease",
										textShadow: "0 0 10px #fff",
										boxShadow: "0 0 5px#cc2e3d, 0 0 10px#cc2e3d",
										"&:hover": {
											backgroundColor: "#d93d0d",
											boxShadow: "0 0 5px#cc2e3d, 0 0 10px#cc2e3d",
										},
									}}>
									Close
								</Button>
							</Box>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};

export default Payments;
