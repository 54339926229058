import { getLastCloses } from "../../streaming.js";
import { store } from "../../../../store/store";

export const runVelandiaSecret = (tvWidget, candlesQty, slRetCandles = 6, threshold = 0.25, amount) => {
	console.log("RUN VELANDIA CAPITAL BOT");
	let cross1H = false;
	let emaData15M = [];
	let emaData5M = [];
	let ema20_5M = null;
	let ema50_5M = null;
	let ema200_5M = null;
	let ema50_15M = null;
	let ema200_15M = null;
	let totalCrosses = 0;
	let winningCrosses = 0;
	let totalProfit = 0;
	let totalLoss = 0;
	let maxDrawdown = 0;
	let currentDrawdown = 0;
	let current15MCandle;
	let previousBar = null;
	let ema200Slope = null;

	let trade = {
		time: null,
		price: null,
		direction: null,
	};

	let condition5M = {
		active: false,
		time: null,
		direction: null,
	};

	let condition15M = {
		active: false,
		time: null,
	};

	const init = async () => {
		const visibleRange = tvWidget.activeChart().getVisibleRange();
		const from = visibleRange.from * 1000;
		const to = visibleRange.to * 1000;

		const ohlcData15M = await getLastCloses(900, null, from, to);
		console.log("15M Data received:", ohlcData15M.length);

		const ohlcData5M = await getLastCloses(300, null, from, to);
		console.log("5M Data received:", ohlcData5M.length);

		if (ohlcData5M.length > 0) {
			await processData15M(ohlcData15M);
			await processData5M(ohlcData5M);
		}
	};

	init();

	const processRealTimeUpdate = () => {
		const lastBar = store.getState().lastBar;
		if (!lastBar) return;
		lastBar.time = lastBar.epoch * 1000;

		if (!previousBar) {
			console.log("PREVIOUS BAR TIME:", previousBar);
			previousBar = lastBar;
			return;
		}

		if (lastBar.open_time !== previousBar.open_time) {
			console.log("Previous update:", previousBar);
			console.log("Real-time update:", lastBar);

			emaData5M.push(previousBar);
			processData5M([previousBar]);
			previousBar = lastBar;
		}

		if (!current15MCandle || lastBar.time >= current15MCandle.time + 15 * 60 * 1000) {
			if (current15MCandle) {
				emaData15M.push(current15MCandle);
				processData15M([current15MCandle]);
			}
			current15MCandle = { ...lastBar };
		} else {
			current15MCandle.close = lastBar.close;
			current15MCandle.high = Math.max(current15MCandle.high, lastBar.high);
			current15MCandle.low = Math.min(current15MCandle.low, lastBar.low);
		}
	};

	store.subscribe(() => {
		processRealTimeUpdate();
	});

	const calculateEMA = (period, data) => {
		let multiplier = 2 / (period + 1);
		return data.reduce((acc, val, index) => {
			if (index === 0) return [val.close];
			let ema = (val.close - acc[index - 1]) * multiplier + acc[index - 1];
			return [...acc, ema];
		}, []);
	};

	const drawEMA = (tvWidget, emaData, color50, color200) => {
		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema50 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema50 },
				],
				{
					shape: "trend_line",
					overrides: {
						linecolor: color50,
						linewidth: 4,
					},
				}
			);
		}

		for (let i = 1; i < emaData.length; i++) {
			tvWidget.activeChart().createMultipointShape(
				[
					{ time: emaData[i - 1].time / 1000, price: emaData[i - 1].ema200 },
					{ time: emaData[i].time / 1000, price: emaData[i].ema200 },
				],
				{
					shape: "trend_line",
					overrides: {
						linecolor: color200,
						linewidth: 4,
					},
				}
			);
		}
	};

	const processData15M = (data) => {
		ema50_15M = calculateEMA(50, data);
		ema200_15M = calculateEMA(200, data);

		emaData15M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema50: ema50_15M[i],
			ema200: ema200_15M[i],
		}));
	};

	const processData5M = (data) => {
		ema20_5M = calculateEMA(20, data);
		ema50_5M = calculateEMA(50, data);
		ema200_5M = calculateEMA(200, data);

		emaData5M = data.map((candle, i) => ({
			time: candle.time,
			price: candle.close,
			high: candle.high,
			low: candle.low,
			ema20: ema20_5M[i],
			ema50: ema50_5M[i],
			ema200: ema200_5M[i],
			ema200Slope: i >= 10 ? (ema200_5M[i] - ema200_5M[i - 10]) / 10 : null,
		}));

		console.log(
			"Últimos slopes calculados:",
			emaData5M.slice(-5).map((d) => d.ema200Slope)
		);

		detectCross5M(); // Asegúrate de que detectCross5M se llame después del cálculo
	};

	const detectCross5M = async () => {
		for (let i = 1; i < emaData5M.length; i++) {
			const previous = emaData5M[i - 1];
			const current = emaData5M[i];

			if (previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				condition5M = {
					active: true,
					time: current.time,
					direction: "up",
					index: i,
				};
				console.log("DETECT UP CROSS 5M", new Date(previous.time).toLocaleString("en-US", { timeZone: "UTC" }));
				await monitorThirdCondition(i);
				markCrossM5(tvWidget, current.time, current.price, "up5");
				detectCross15M();
			} else if (previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				condition5M = {
					active: true,
					time: current.time,
					direction: "down",
					index: i,
				};
				console.log("DETECT DOWN CROSS 5M", new Date(previous.time).toLocaleString("en-US", { timeZone: "UTC" }));
				await monitorThirdCondition(i);
				markCrossM5(tvWidget, current.time, current.price, "down5");
				detectCross15M();
			}
		}
	};

	const detectCross15M = () => {
		const endTime = condition5M.time + 12 * 60 * 60 * 1000;
		const check15M = emaData15M.filter((data) => data.time >= condition5M.time && data.time <= endTime);

		for (let i = 1; i < check15M.length; i++) {
			const previous = check15M[i - 1];
			const current = check15M[i];

			if (condition5M.direction === "up" && previous.ema50 < previous.ema200 && current.ema50 > current.ema200) {
				condition15M = {
					active: true,
					time: current.time,
					direction: "up",
				};
				console.log(
					"XX XX XX XX XX XX Segunda condición activada: Cruce 15M alcista",
					new Date(previous.time).toLocaleString("en-US", { timeZone: "UTC" })
				);
				markCrossM5(tvWidget, current.time, current.price, "up15");
			} else if (condition5M.direction === "down" && previous.ema50 > previous.ema200 && current.ema50 < current.ema200) {
				condition15M = {
					active: true,
					time: current.time,
					direction: "down",
				};
				console.log(
					"XX XX XX XX XX XX Segunda condición activada: Cruce 15M bajista",
					new Date(previous.time).toLocaleString("en-US", { timeZone: "UTC" })
				);
				markCrossM5(tvWidget, current.time, current.price, "down15");
			}
		}
	};

	const monitorThirdCondition = async (startIndex) => {
		const lastCandle = emaData5M[startIndex];
		console.log("RANGED MARKET", Math.abs(lastCandle.ema200Slope));
		// if (Math.abs(lastCandle.ema200Slope) < 0.35) {
		// 	return;
		// }

		const nextCrossIndex = emaData5M.findIndex((data, i) => {
			if (i <= startIndex) return false;
			const previous = emaData5M[i - 1];
			return condition5M.direction === "up"
				? previous.ema50 > previous.ema200 && data.ema50 < data.ema200
				: previous.ema50 < previous.ema200 && data.ema50 > data.ema200;
		});

		const dataSlice = nextCrossIndex !== -1 ? emaData5M.slice(startIndex, nextCrossIndex) : emaData5M.slice(startIndex);
		const check5M = dataSlice.filter((data) => data.time >= condition5M.time);
		const check15M = emaData15M.filter((data) => data.time >= check5M[0].time && data.time <= check5M[check5M.length - 1].time);

		for (const data of check5M) {
			const m15Data = check15M.find((m15) => data.time >= m15.time && data.time < m15.time + 15 * 60 * 1000);
			if (!m15Data) continue;

			const m15Ema200 = Number(m15Data.ema200);
			const m15Ema50 = Number(m15Data.ema50);
			const m5Ema200 = Number(data.ema200);
			const m5Ema50 = Number(data.ema50);

			const emasAligned =
				condition5M.direction === "up"
					? m15Ema200 < m15Ema50 && m5Ema200 < m5Ema50 && m15Ema200 < m5Ema200
					: m15Ema200 > m15Ema50 && m5Ema200 > m5Ema50 && m15Ema200 > m5Ema200;

			if (emasAligned) {
				const prevData = check5M[check5M.indexOf(data) - 1];
				if (condition5M.direction === "up" && prevData?.price > prevData?.ema20 && data.price < data.ema20 && data.ema20 > data.ema50) {
					trade = {
						time: data.time,
						price: data.price,
						direction: "up",
					};
					console.log("Señal de entrada detectada - Precio cruza EMA20 hacia abajo");
					markCrossM5(tvWidget, data.time, data.price, "trade");
					const relevantCheck15M = check15M.filter((d) => d.time >= data.time && d.time <= data.time + 15 * 60 * 1000);
					await drawEMA(tvWidget, relevantCheck15M, "orange", "green");
					calculateTradeTargets();
				} else if (
					condition5M.direction === "down" &&
					prevData?.price < prevData?.ema20 &&
					data.price > data.ema20 &&
					data.ema20 < data.ema50
				) {
					trade = {
						time: data.time,
						price: data.price,
						direction: "down",
					};

					console.log("Señal de entrada detectada - Precio cruza EMA20 hacia arriba");
					markCrossM5(tvWidget, data.time, data.price, "trade");
					const relevantCheck15M = check15M.filter((d) => d.time >= data.time && d.time <= data.time + 15 * 60 * 1000);
					await drawEMA(tvWidget, relevantCheck15M, "orange", "green");
					calculateTradeTargets();
				}
			}
		}
	};

	const calculateTradeTargets = () => {
		const averageCandleSize = calculateCandleSizes();
		const endTime = trade.time + 12 * 60 * 60 * 1000;
		const check5M = emaData5M.filter((data) => data.time >= trade.time && data.time <= endTime);

		totalCrosses++;
		let highestPrice = trade.price;
		let lowestPrice = trade.price;

		if (trade.direction === "up") {
			for (let j = check5M.findIndex((d) => d.time === trade.time) + 1; j < check5M.length; j++) {
				const closePrice = Number(check5M[j].price);
				highestPrice = Math.max(highestPrice, closePrice);
				const trailingStop = highestPrice - averageCandleSize * slRetCandles;

				if (closePrice <= trailingStop) {
					const candleMovements = Math.abs(closePrice - trade.price) / averageCandleSize;
					const profit = closePrice > trade.price ? candleMovements * amount : -(candleMovements * amount);
					if (profit > 0) {
						winningCrosses++;
						totalProfit += profit;
					} else {
						totalLoss += Math.abs(profit);
					}
					trackDrawdown(profit);
					markProfit1M(tvWidget, check5M[j].time, closePrice, profit > 0 ? "win" : "loss", Math.abs(profit));
					const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
					updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
					trade = null;
					break;
				}
			}
		} else if (trade.direction === "down") {
			for (let j = check5M.findIndex((d) => d.time === trade.time) + 1; j < check5M.length; j++) {
				const closePrice = Number(check5M[j].price);
				lowestPrice = Math.min(lowestPrice, closePrice);
				const trailingStop = lowestPrice + averageCandleSize * slRetCandles;

				if (closePrice >= trailingStop) {
					const candleMovements = Math.abs(closePrice - trade.price) / averageCandleSize;
					const profit = closePrice < trade.price ? candleMovements * amount : -(candleMovements * amount);
					if (profit > 0) {
						winningCrosses++;
						totalProfit += profit;
					} else {
						totalLoss += Math.abs(profit);
					}
					trackDrawdown(profit);
					markProfit1M(tvWidget, check5M[j].time, closePrice, profit > 0 ? "win" : "loss", Math.abs(profit));
					const winRate = ((winningCrosses / totalCrosses) * 100).toFixed(2);
					updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss);
					trade = null;
					break;
				}
			}
		}
	};

	const calculateCandleSizes = () => {
		const lastTwentyCandles = emaData5M.slice(-20);
		const priceMovements = lastTwentyCandles.map((candle) =>
			Math.abs(candle.price - (candle.open || lastTwentyCandles[lastTwentyCandles.indexOf(candle) - 1]?.price || candle.price))
		);

		const mean = priceMovements.reduce((a, b) => a + b, 0) / priceMovements.length;
		const variance = priceMovements.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / priceMovements.length;
		const stdDev = Math.sqrt(variance);
		const normalCandleSizes = priceMovements.filter((movement) => movement <= mean + 2 * stdDev);

		return normalCandleSizes.reduce((a, b) => a + b, 0) / normalCandleSizes.length;
	};

	const trackDrawdown = (profit) => {
		currentDrawdown = Math.min(currentDrawdown + profit, 0);
		maxDrawdown = Math.min(maxDrawdown, currentDrawdown);
	};

	const markProfit1M = (tvWidget, time, price, direction, profit) => {
		const color = direction === "win" ? "green" : "red";
		const prefix = direction === "win" ? "$" : "-$";

		tvWidget.activeChart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "balloon",
				text: `${prefix}${Math.abs(profit).toFixed(2)}`,
				overrides: {
					backgroundColor: color,
					color: "white",
					fontsize: 20,
					bold: true,
					zIndex: 100,
				},
				zOrder: "top",
			}
		);
	};

	const markCrossM5 = (tvWidget, time, price, direction) => {
		const color =
			direction === "up5"
				? "#00E6F9"
				: direction === "down5"
				? "#D631ED"
				: direction === "up15"
				? "#00FF00"
				: direction === "down15"
				? "#FFFF00"
				: direction === "trade"
				? "#fff"
				: "#D631ED";

		const cross = 0xf00d;
		const check = 0xf00d;
		const flag = 0xf087;

		tvWidget.chart().createShape(
			{ time: time / 1000, price: price },
			{
				shape: "icon",
				overrides: {
					color: color,
					size: 30,
					zOrder: "top",
				},
				icon: direction === "trade" ? flag : cross,
			}
		);
	};

	window.currentTvWidget = tvWidget;
	window.clearVelandiaCapital = (widget) => {
		console.log("Clearing spike shield");
		widget.activeChart().removeAllShapes();
		const statsBox = document.getElementById("statsBox");
		if (statsBox) {
			statsBox.remove();
		}
	};

	function updateStatsDisplay(totalCrosses, winningCrosses, winRate, totalProfit, totalLoss) {
		const statsBox = document.getElementById("statsBox");
		if (!statsBox) {
			const box = document.createElement("div");
			box.id = "statsBox";
			box.style.cssText = `
				position: absolute;
				bottom: 20px;
				left: 20px;
				width: 250px;
				height: 300px;
				background-color: rgba(23, 27, 38, 0.9);
				padding: 15px;
				border-radius: 8px;
				color: white;
				box-shadow: 0 0 5px #4599d9, 0 0 10px #4599d9;
				font-family: 'Orbitron', sans-serif;
				font-size: 12px;
				line-height: 1.5;
				cursor: move;
				user-select: none;
			`;
			document.body.appendChild(box);
			makeDraggable(box);
		}

		const netProfit = totalProfit - totalLoss;
		const profitColor = netProfit >= 0 ? "green" : "red";
		const days = Math.floor(candlesQty / (24 * 60));
		const remainingHours = Math.floor((candlesQty % (24 * 60)) / 60);

		document.getElementById("statsBox").innerHTML = `
			<div style="position: absolute; top: 5px; right: 10px; cursor: pointer; font-size: 16px; color: red;" 
			 onclick="window.clearVelandiaCapital(window.currentTvWidget); window.currentTvWidget.chart().removeAllShapes();">✖</div>
			<p style="font-weight: bold; color: #4599d9;">Periodo: ${days}d ${remainingHours}h (${candlesQty} velas)</p>
			<p>Cruces Totales: <span style="float: right;">${totalCrosses}</span></p>
			<p>Cruces Ganadores: <span style="float: right;">${winningCrosses}</span></p>
			<p>Tasa de Éxito: <span style="float: right;">${winRate}%</span></p>
			<p>Drawdown Máximo: <span style="float: right; color: orange;">${maxDrawdown.toFixed(2)}</span></p>
			<hr style="border: 1px solid #4599d9; margin: 8px 0;">
			<p>Ganancia Total: <span style="float: right; color: ${profitColor};">${totalProfit.toFixed(2)}</span></p>
			<p>Pérdida Total: <span style="float: right; color: red;">${totalLoss.toFixed(2)}</span></p>
			<p>Resultado Neto: <span style="float: right; color: ${profitColor};">${netProfit.toFixed(2)}</span></p>
			<hr style="border: 1px solid #4599d9; margin: 8px 0;">
			<p>Pendiente EMA200: <span style="float: right;">${ema200Slope ? ema200Slope.toFixed(6) : "N/A"}</span></p>
			<p>Umbral: <span style="float: right;">${Number(threshold).toFixed(6)}</span></p>
		`;
	}

	function makeDraggable(element) {
		let pos1 = 0,
			pos2 = 0,
			pos3 = 0,
			pos4 = 0;
		element.onmousedown = dragMouseDown;

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			element.style.top = element.offsetTop - pos2 + "px";
			element.style.left = element.offsetLeft - pos1 + "px";
		}

		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}
};
