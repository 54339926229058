import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box, List, ListItem, ListItemText, Typography, Grid, ListItemButton, Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { store } from "../../../../store/store.js";
import axios from "axios";
import { toast } from "react-toastify";
import Payments from "./Payments.js";
import "react-credit-cards/es/styles-compiled.css";

const DrawingsPage = () => {
	const tvWidget = store((state) => state.chart);
	const user = JSON.parse(localStorage.getItem("user"));
	const [traderToCopy, setTraderToCopy] = useState("");
	const [followTo, setFollowTo] = useState("");
	const [traderName, setTraderName] = useState("");
	const [drawings, setDrawings] = useState([]);
	const [matchTrader, setMatchTrader] = useState(false);
	const [showPayments, setShowPayments] = useState(false);
	const [service, setService] = useState("drawings");
	const [price, setPrice] = useState(0);
	const [tabValue, setTabValue] = useState(0);
	const [isSearching, setIsSearching] = useState(false);
	const botDetails = {
		Guardar: {
			images: "/assets/save.webp",
			details:
				"Guarda tus gráficos y análisis técnicos personalizados para compartirlos con otros traders. Monetiza tu experiencia ofreciendo tus análisis a cambio de una tarifa. Crea una comunidad de trading y genera ingresos pasivos compartiendo tus conocimientos del mercado.",
		},
		Cargar: {
			images: "/assets/load.webp",
			details:
				"Carga tus gráficos y análisis técnicos guardados previamente. Accede a una biblioteca de análisis de otros traders expertos. Mejora tus estrategias de trading aprendiendo de los mejores y adaptando sus ideas a tu estilo de trading.",
		},
		Eliminar: {
			images: "/assets/remove.webp",
			details:
				"Elimina gráficos y análisis técnicos guardados que ya no necesitas. Mantén tu espacio de trabajo organizado y enfocado en tus estrategias actuales. Asegúrate de conservar solo la información más relevante y actualizada para tus operaciones de trading.",
		},
	};
	const [selectedBot, setSelectedBot] = useState("Guardar");

	useEffect(() => {
		const saveTrader = async () => {
			const trader = (await localStorage.getItem("traderToCopy")) || "";
			setTraderToCopy(trader);
		};
		saveTrader();
	}, []);

	useEffect(() => {
		if (!tvWidget) return;

		// Get drawings from localStorage
		const symbol = tvWidget.chart().symbol();
		const existingDrawings = localStorage.getItem("chartDrawings");
		const savedData = existingDrawings ? JSON.parse(existingDrawings) : [];
		const symbolData = savedData.find((item) => item.symbol === symbol);
		const drawings = symbolData ? symbolData.drawings : [];

		if (drawings && drawings.length > 0) {
			tvWidget.chart().removeAllShapes();

			drawings.forEach((drawing) => {
				const shapeOptions = drawing.properties;
				const shapePoints = drawing.points;

				if (shapePoints.length > 1) {
					tvWidget.chart().createMultipointShape(shapePoints, {
						shape: drawing.name,
						...shapeOptions,
					});
				} else if (shapePoints.length === 1) {
					tvWidget.chart().createShape(shapePoints[0], {
						shape: drawing.name,
						...shapeOptions,
					});
				}
			});
		}

		const handleDrawingEvent = (id, type) => {
			const drawings = tvWidget
				.chart()
				.getAllShapes()
				.map((shape) => {
					const shapeDetails = tvWidget.chart().getShapeById(shape.id);
					const properties = shapeDetails ? shapeDetails.getProperties() : {};
					const points = shapeDetails ? shapeDetails.getPoints() : [];

					return {
						id: shape.id,
						name: shape.name,
						points: points.map((p) => ({ time: p.time, price: p.price })),
						properties: properties,
					};
				});

			const symbol = tvWidget.chart().symbol();
			const existingDrawings = localStorage.getItem("chartDrawings");
			let savedData = existingDrawings ? JSON.parse(existingDrawings) : [];

			const existingSymbolIndex = savedData.findIndex((item) => item.symbol === symbol);
			if (existingSymbolIndex !== -1) {
				savedData[existingSymbolIndex].drawings = drawings;
			} else {
				savedData.push({
					symbol: symbol,
					drawings: drawings,
				});
			}

			localStorage.setItem("chartDrawings", JSON.stringify(savedData));
		};

		// Only subscribe if tvWidget is ready
		if (tvWidget.chart()) {
			tvWidget.subscribe("drawing_event", handleDrawingEvent);
		}

		return () => {
			// Only unsubscribe if tvWidget still exists and is ready
			if (tvWidget && tvWidget.chart()) {
				tvWidget.unsubscribe("drawing_event", handleDrawingEvent);
			}
		};
	}, [tvWidget]);

	const handleSearch = async () => {
		if (isSearching) return;

		setIsSearching(true);

		try {
			const copyTo = traderToCopy.replace("GRX", "");
			const payload = { userId: user.id, toFollow: copyTo };

			if (!payload.userId || typeof payload.userId !== "number") {
				console.error("Invalid userId:", payload.userId);
				return;
			}

			if (!payload.toFollow || payload.toFollow.trim() === "") {
				toast.error("Digite el código de Trader a seguir", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
				return;
			}

			axios
				.post("https://mitserver.app:9005/findIdDrawings", payload)
				.then((response) => {
					setTraderName(response.data.user);
					const validDrawings = response.data.paidDrawings.filter((drawing) => {
						if (typeof drawing === "string") {
							return drawing.trim() !== "";
						}
						return typeof drawing === "object" && drawing.id;
					});

					setDrawings(validDrawings);
					setPrice(response.data.drawingsPrice);
					setFollowTo(copyTo);

					if (validDrawings.length > 0) {
						const matchingDrawing = validDrawings.find((drawing) => drawing === String(copyTo));

						if (matchingDrawing) {
							setMatchTrader(true);
							const matchingId = matchingDrawing;
						} else {
							setMatchTrader(false);
						}
					}
				})
				.catch((error) => {
					console.error("Error loading drawings:", error);
				});
		} catch (error) {
			console.error("Error during search:", error);
		} finally {
			setIsSearching(false);
		}
	};

	let savedData = [];

	const saveDrawings = () => {
		// Get existing drawings from localStorage and ensure it's an array
		const existingDrawings = localStorage.getItem("chartDrawings");
		savedData = existingDrawings ? (Array.isArray(JSON.parse(existingDrawings)) ? JSON.parse(existingDrawings) : []) : [];

		const drawings = tvWidget
			.chart()
			.getAllShapes()
			.map((shape) => {
				const shapeDetails = tvWidget.chart().getShapeById(shape.id);
				const properties = shapeDetails ? shapeDetails.getProperties() : {};
				const points = shapeDetails ? shapeDetails.getPoints() : [];
				console.log("SHAPE", points);
				return {
					id: shape.id,
					name: shape.name,
					points: points.map((p) => ({ time: p.time, price: p.price })),
					properties: properties,
				};
			});

		const symbol = tvWidget.chart().symbol();

		const existingSymbolIndex = savedData.findIndex((item) => item.symbol === symbol);

		if (existingSymbolIndex !== -1) {
			savedData[existingSymbolIndex].drawings = drawings;
		} else {
			savedData.push({
				symbol: symbol,
				drawings: drawings,
			});
		}
		localStorage.setItem("chartDrawings", JSON.stringify(savedData));

		axios
			.post("https://mitserver.app:9005/saveDrawings", { userId: user.id, drawings: savedData })
			.then((response) => {
				console.log("Drawings saved successfully:", response.data);
				toast.success("Dibujos guardados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			})
			.catch((error) => {
				console.error("Error saving drawings:", error);
			});
	};

	const loadDrawings = (userDrawings) => {
		const symbol = tvWidget.chart().symbol();
		const payload = userDrawings ? { userId: userDrawings } : { userId: user.id };

		axios
			.post("https://mitserver.app:9005/loadDrawings", payload)
			.then((response) => {
				const drawingsData = response.data.drawingsData;
				const symbolData = drawingsData.find((item) => item.symbol === symbol);
				const drawings = symbolData ? symbolData.drawings : [];

				if (drawings && drawings.length > 0) {
					tvWidget.chart().removeAllShapes();

					drawings.forEach((drawing) => {
						const shapeOptions = drawing.properties;
						const shapePoints = drawing.points;

						if (shapePoints.length > 1) {
							tvWidget.chart().createMultipointShape(shapePoints, {
								shape: drawing.name,
								...shapeOptions,
							});
						} else if (shapePoints.length === 1) {
							tvWidget.chart().createShape(shapePoints[0], {
								shape: drawing.name,
								...shapeOptions,
							});
						} else {
							console.log(`Invalid points for drawing: ${drawing.name}`);
						}
					});
					toast.success("Graficos cargados exitosamente para el símbolo: " + symbol, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
				} else {
					toast.success("No hay gráficos para el símbolo: " + symbol, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: "dark",
					});
				}
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const deleteDrawings = async () => {
		const symbol = tvWidget.chart().symbol();
		const payload = { userId: user.id, symbol: symbol };

		axios
			.post("https://mitserver.app:9005/removeDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleBotSelection = () => {
		if (selectedBot === "Guardar") {
			saveDrawings();
		} else if (selectedBot === "Cargar") {
			const copyTo = traderToCopy.replace("GRX", "");
			loadDrawings(copyTo);
		} else if (selectedBot === "Eliminar") {
			deleteDrawings();
		}
	};

	const handleFollower = (traderId) => {
		const payload = { userId: user.id, toFollow: traderId };
		axios
			.post("https://mitserver.app:9005/followDrawings", payload)
			.then((response) => {
				tvWidget.chart().removeAllShapes();
				toast.success("Dibujos eliminados exitosamente", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					theme: "dark",
				});
			})
			.catch((error) => {
				console.error("Error loading drawings:", error);
			});
	};

	const handleSaveIndicators = () => {
		console.log("Saving indicators...", Object.keys(tvWidget));
		tvWidget
			.saveIndicatorTemplate()
			.then((template) => {
				localStorage.setItem("indicatorTemplate", JSON.stringify(template));
				console.log("Indicators saved:", template);
			})
			.catch((error) => {
				console.error("Error saving indicators:", error);
			});
	};

	const handleLoadIndicators = () => {
		const template = JSON.parse(localStorage.getItem("indicatorTemplate"));
		if (template) {
			tvWidget
				.loadIndicatorTemplate(template)
				.then(() => {
					console.log("Indicators loaded successfully");
				})
				.catch((error) => {
					console.error("Error loading indicators:", error);
				});
		} else {
			console.warn("No saved indicators found");
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "linear-gradient(to bottom, #000000, #000033)",
				padding: 1,
			}}>
			<Tabs
				value={selectedBot ? Object.keys(botDetails).indexOf(selectedBot) : 0}
				onChange={(e, newValue) => setSelectedBot(Object.keys(botDetails)[newValue])}
				sx={{
					minHeight: "35px",
					"& .MuiTabs-indicator": { display: "none" },
					"& .MuiTab-root": {
						minHeight: "35px",
						height: "35px",
						padding: "0 15px",
						borderRadius: "4px 4px 0 0",
						marginRight: "2px",
						"&.Mui-selected": {
							border: "1px solid #4599d9",
							borderBottom: "none",
							boxShadow: "0 0 10px #4599d9",
						},
					},
				}}>
				{Object.keys(botDetails).map((bot) => (
					<Tab
						key={bot}
						label={bot}
						sx={{
							textTransform: "capitalize",
							fontSize: "0.875rem",
							fontFamily: "'Orbitron', sans-serif",
							color: "#fff",
							"&.Mui-selected": {
								color: "#4599d9",
								textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
							},
							"&:hover": {
								color: "#cc2e3d",
								textShadow: "0 0 10px#cc2e3d, 0 0 20px#cc2e3d",
							},
						}}
					/>
				))}
			</Tabs>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					height: "calc(100% - 48px)",
					overflowY: "auto",
					padding: "10px",
				}}>
				{selectedBot && (
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
						<img
							src={botDetails[selectedBot].images}
							alt={`${selectedBot} image`}
							style={{
								width: "100%",
								borderRadius: 8,
								boxShadow: "0 0 5px #4599d9, 0 0 20px #4599d9",
							}}
						/>

						<Typography
							sx={{
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
								fontSize: "0.9rem",
							}}>
							{botDetails[selectedBot].details}
						</Typography>

						{selectedBot === "Cargar" && (
							<Box sx={{ width: "100%" }}>
								<Box sx={{ display: "flex", gap: 1 }}>
									<TextField
										label="Trader a seguir"
										value={traderToCopy}
										onChange={async (e) => {
											setTraderToCopy(e.target.value);
											await localStorage.setItem("traderToCopy", e.target.value);
										}}
										sx={{
											flex: 1,
											"& .MuiOutlinedInput-root": {
												color: "#fff",
												backgroundColor: "#2a2e39",
												fontFamily: "'Orbitron', sans-serif",
											},
										}}
									/>
									<IconButton
										onClick={handleSearch}
										disabled={isSearching}
										sx={{
											backgroundColor: isSearching ? "#ccc" : "#cc2e3d",
											"&:hover": {
												backgroundColor: isSearching ? "#ccc" : "#d93d0d",
											},
										}}>
										{isSearching ? <CircularProgress size={24} color="inherit" /> : <SearchIcon sx={{ color: "#fff" }} />}
									</IconButton>
								</Box>
								<Typography
									sx={{
										color: "#fff",
										textAlign: "center",
										mt: 1,
										fontFamily: "'Orbitron', sans-serif",
									}}>
									{traderName}
								</Typography>
							</Box>
						)}

						<Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
							{selectedBot === "Cargar" && (
								<Button
									onClick={() => loadDrawings()}
									sx={{
										color: "#fff",
										backgroundColor: "#4599d9",
										boxShadow: "0 0 5px #4599d9, 0 0 15px #4599d9",
										"&:hover": { backgroundColor: "#2a5d8a" },
										fontFamily: "'Orbitron', sans-serif",
									}}>
									Cargar
								</Button>
							)}

							<Button
								onClick={() => {
									if (matchTrader) {
										handleBotSelection(selectedBot);
									} else {
										const resp = selectedBot === "Cargar" ? "Comprar" : selectedBot === "Eliminar" ? "Eliminar" : "Guardar";
										if (resp === "Comprar") {
											if (traderName) {
												setShowPayments(true);
											} else {
												toast.error("Debes buscar un trader a seguir", {
													position: "top-right",
													autoClose: 3000,
													hideProgressBar: false,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: true,
													theme: "dark",
												});
											}
										} else {
											handleBotSelection(selectedBot);
										}
									}
								}}
								sx={{
									color: "#fff",
									backgroundColor: "#cc2e3d",
									boxShadow: "0 0 5px#cc2e3d, 0 0 15px#cc2e3d",
									"&:hover": { backgroundColor: "#d93d0d" },
									fontFamily: "'Orbitron', sans-serif",
								}}>
								{selectedBot === "Cargar"
									? matchTrader
										? "Cargar"
										: "Comprar"
									: selectedBot === "Eliminar"
									? "Eliminar"
									: "Guardar"}
							</Button>
						</Box>

						<Payments
							openPayments={showPayments}
							onClosePayments={() => setShowPayments(false)}
							service={service}
							price={price}
							buyTo={followTo}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default DrawingsPage;
