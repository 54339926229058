import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Button, Switch, TextField, Divider, Slider, IconButton } from "@mui/material";
import { setSymbolStorage } from "../../streaming";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { store } from "../../../../store/store";
import socket from "../../../../socket/Socket";

const NewSidePanel = () => {
	const [tradeType, setTradeType] = useState("multipliers");
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const chartType = localStorage.getItem("chartType");
	const account = localStorage.getItem("account");
	const positionsData = store((state) => state.positionsData);
	const derivWS = store((state) => state.derivWS);
	const balance = store((state) => state.balance);
	const tradeAmount = store((state) => state.tradeAmount);
	const commission = store((state) => state.commission);
	const slPrice = store((state) => state.slPrice);
	const tpPrice = store((state) => state.tpPrice);
	const slTpButton = store((state) => state.slTpButton);
	const retCandles = store((state) => state.retCandles);
	const trailingStop = store((state) => state.trailingStop);
	const [reports, setReports] = useState([]);
	const [slTpButtonColor, setSlTpButtonColor] = useState("#808080");
	const [inputValue, setInputValue] = useState("");
	const [retCandlesQty, setRetCandlesQty] = useState("");
	const [riskAmount, setRiskAmount] = useState("");
	const [stopLoss, setStopLoss] = useState("");
	const [takeProfit, setTakeProfit] = useState("");
	const subscriptionsRef = useRef({});

	useEffect(() => {
		if (!selectedAccount || !derivWS) return;
		getToken();
		return () => {
			cleanupSubscriptions();
			window.turnOffRiskLines();
		};
	}, []);

	useEffect(() => {
		if (!account) return;
		if (!account.token && account.token !== selectedAccount.token) {
			cleanupSubscriptions();
		}
	}, [account]);

	useEffect(() => {
		processOpenPositions();
	}, [positionsData]);

	useEffect(() => {
		const serRiskStuff = async () => {
			const iv = await localStorage.getItem("inputValue");
			const rcq = await localStorage.getItem("retCandleQty");
			const rA = await localStorage.getItem("riskAmount");

			setInputValue(iv ? Number(iv) : 1);
			setRetCandlesQty(rcq ? Number(rcq) : 1);
			setRiskAmount(rA ? Number(rA) : 1);
		};
		if (balance !== null && balance > 0) {
			serRiskStuff();
		}
	}, [balance]);

	const cleanupSubscriptions = () => {
		Object.keys(subscriptionsRef.current).forEach((subscriptionId) => {
			const forgetMessage = JSON.stringify({
				forget: subscriptionId,
			});

			if (derivWS.readyState === WebSocket.OPEN) {
				derivWS.send(forgetMessage);
			}
		});
		setReports([]);
		subscriptionsRef.current = {};
	};

	const getToken = async () => {
		const maxRetries = 5;
		const retryDelay = 2000;
		let retryCount = 0;

		let selAcc = account;
		if (!account) {
			selAcc = JSON.parse(localStorage.getItem("selectedAccount"));
		} else {
			selAcc = account;
		}

		const sendMessage = JSON.stringify({ authorize: selAcc.token, passthrough: { history: true } });
		derivWS.send(sendMessage);
	};

	const processOpenPositions = async () => {
		const positionsData = store.getState().positionsData;
		if (Object.keys(positionsData).length === 0) {
			setReports([]);
			return;
		}

		if (chartType === "Opciones") {
			const multipliersId = await localStorage.getItem("multiplierSubsId");
			if (multipliersId) {
				derivWS.send(
					JSON.stringify({
						forget: multipliersId,
					})
				);
				await localStorage.setItem("multiplierSubsId", null);
			}
			const position = positionsData.proposal_open_contract;
			if (!position) {
				return;
			}

			setReports((prevReports) => {
				let updatedReports = [...prevReports];
				const existingIndex = updatedReports.findIndex((report) => report.contract_id === position.contract_id);

				if (position.status === "open") {
					if (existingIndex !== -1) {
						updatedReports[existingIndex] = position;
					} else {
						updatedReports.push(position);
					}
				} else if (existingIndex !== -1) {
					updatedReports.splice(existingIndex, 1);
				}

				return updatedReports;
			});
			return;
		}
		if (!positionsData || positionsData.length === 0) {
			return;
		}

		subscriptionsRef.current[positionsData.id] = positionsData;
		localStorage.setItem("multiplierSubsId", positionsData.id);

		setReports((prevReports) => {
			const updatedReports = prevReports.filter(
				(report) => report.contract_id !== positionsData.contract_id || positionsData.status !== "sold"
			);
			const reportIndex = updatedReports.findIndex((report) => report.contract_id === positionsData.contract_id);
			if (reportIndex !== -1) {
				updatedReports[reportIndex] = {
					...updatedReports[reportIndex],
					...positionsData,
				};
			} else if (positionsData.status !== "sold") {
				updatedReports.push(positionsData);
			}

			return updatedReports;
		});
	};

	const handleDelete = (row) => {
		const removeData = {
			contractId: row.contract_id,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
			symbol: row.underlying,
			symbolName: row.display_name,
		};
		socket.emit("sellContract", removeData);
	};

	const handleCellClick = async (row) => {
		window.changeSymbol(row.underlying);
		window.getLines();
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				background: "linear-gradient(to bottom, #000000, #000033)",
				padding: 1,
				position: "relative",
			}}>
			<IconButton
				onClick={() => window.hidePanel()}
				sx={{
					position: "absolute",
					top: "10px",
					right: "10px",
					color: "#ffffff",
					"&:hover": {
						backgroundColor: "rgba(255, 255, 255, 0.1)",
					},
				}}>
				<DashboardIcon />
			</IconButton>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: "5px",
					backgroundColor: "transparent",
					paddingLeft: "15px",
					paddingRight: "15px",
					borderTopLeftRadius: "10px",
					borderTopRightRadius: "10px",
				}}>
				<Typography variant="h6" sx={{ color: "#ffffff", textAlign: "center", fontWeight: "bold", fontFamily: "'Orbitron', sans-serif" }}>
					Multiplicadores
				</Typography>

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						alignItems: "center",
						mb: 3,
					}}>
					<Box sx={{ position: "relative" }}>
						<Box
							component="img"
							src="/assets/upButton.png"
							sx={{
								width: "200px",
								height: "50px",
								cursor: "pointer",
								borderRadius: "10px",
								transition: "transform 0.2s",
								"&:hover": {
									transform: "scale(1.05)",
									boxShadow: "0 0 15px #4599d9",
								},
							}}
							onClick={() => window.buyMultUpMultDn("MULTUP")}
						/>
						<Typography
							sx={{
								position: "absolute",
								right: "10px",
								bottom: "45%",
								fontSize: "1rem",
								color: "white",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							${tradeAmount ? (tradeAmount === null || tradeAmount === undefined ? "0.00" : Number(tradeAmount).toFixed(2)) : "0.00"}
						</Typography>
						<Typography
							sx={{
								position: "absolute",
								right: "5px",
								bottom: "25%",
								fontSize: "0.5rem",
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							Commission
						</Typography>
						<Typography
							sx={{
								position: "absolute",
								right: "5px",
								bottom: "5%",
								fontSize: "0.6rem",
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							${commission ? Number(commission).toFixed(2) : "0.00"}
						</Typography>
					</Box>

					<Box sx={{ position: "relative" }}>
						<Box
							component="img"
							src="/assets/downButton.png"
							sx={{
								width: "200px",
								height: "50px",
								cursor: "pointer",
								borderRadius: "10px",
								transition: "transform 0.2s",
								"&:hover": {
									transform: "scale(1.05)",
									boxShadow: "0 0 15px#cc2e3d",
								},
							}}
							onClick={() => window.buyMultUpMultDn("MULTDOWN")}
						/>
						<Typography
							sx={{
								position: "absolute",
								right: "10px",
								bottom: "45%",
								fontSize: "1rem",
								color: "white",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							${tradeAmount ? (tradeAmount === null || tradeAmount === undefined ? "0.00" : Number(tradeAmount).toFixed(2)) : "0.00"}
						</Typography>
						<Typography
							sx={{
								position: "absolute",
								right: "5px",
								bottom: "25%",
								fontSize: "0.5rem",
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							Commission
						</Typography>
						<Typography
							sx={{
								position: "absolute",
								right: "5px",
								bottom: "5%",
								fontSize: "0.6rem",
								color: "#fff",
								fontFamily: "'Orbitron', sans-serif",
							}}>
							${commission ? Number(commission).toFixed(2) : "0.00"}
						</Typography>
					</Box>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					backgroundColor: "transparent",
					paddingLeft: "15px",
					paddingRight: "15px",
					gap: 4,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Box sx={{ flex: 0.4 }}>
					{/* <Typography sx={{ color: "#ffffff", fontSize: "0.9rem", mt: 1, fontFamily: "'Orbitron', sans-serif", textAlign: "center" }}>
						Riesgo
					</Typography> */}
					<TextField
						fullWidth
						type="number"
						size="small"
						label={<span style={{ fontFamily: "'Orbitron', sans-serif" }}>Riesgo</span>}
						value={riskAmount}
						onChange={(e) => {
							window.handleRiskButton(e);
							setRiskAmount(e.target.value);
						}}
						InputProps={{
							style: { color: "#ffffff", fontFamily: "'Orbitron', sans-serif", fontSize: "0.8rem" },
						}}
						sx={{
							backgroundColor: "#161a25",
							borderRadius: "5px",
							width: "100%",
							margin: "0 auto",
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: "#4599d9",
								},
							},
							fontFamily: "'Orbitron', sans-serif",
						}}
					/>
				</Box>
				<Box sx={{ flex: 0.4 }}>
					{/* <Typography sx={{ color: "#ffffff", fontSize: "0.9rem", mt: 1, fontFamily: "'Orbitron', sans-serif", textAlign: "center" }}>
						Porcentaje
					</Typography> */}
					<TextField
						fullWidth
						type="number"
						size="small"
						label={<span style={{ fontFamily: "'Orbitron', sans-serif" }}>Porcentaje %</span>}
						value={inputValue}
						onChange={(t) => {
							window.handleInputChange(t);
							setInputValue(t.target.value);
							setTimeout(async () => {
								const rA = await localStorage.getItem("riskAmount");
								setRiskAmount(rA ? Number(rA) : 1);
							}, 500);
						}}
						InputProps={{
							style: { color: "#ffffff", fontFamily: "'Orbitron', sans-serif", fontSize: "0.8rem" },
						}}
						sx={{
							backgroundColor: "#161a25",
							borderRadius: "5px",
							width: "100%",
							margin: "0 auto",
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: "#4599d9",
								},
							},
							fontFamily: "'Orbitron', sans-serif",
						}}
					/>
				</Box>
			</Box>

			{retCandles && (
				<Box
					sx={{
						display: "flex",
						backgroundColor: "transparent",
						paddingLeft: "15px",
						paddingRight: "15px",
						gap: 4,
						justifyContent: "center",
						alignItems: "center",
						marginTop: "20px",
					}}>
					<TextField
						fullWidth
						type="number"
						size="small"
						label={<span style={{ fontFamily: "'Orbitron', sans-serif" }}>Ret Candles</span>}
						value={retCandlesQty}
						onChange={async (t) => {
							setRetCandlesQty(t.target.value);
							await localStorage.setItem("retCandlesQty", t.target.value);

							window.handleInputRetCandles(t);
						}}
						InputProps={{
							style: { color: "#ffffff", fontFamily: "'Orbitron', sans-serif", fontSize: "0.8rem" },
						}}
						sx={{
							backgroundColor: "#161a25",
							borderRadius: "5px",
							width: "100%",
							margin: "0 auto",
							"& .MuiOutlinedInput-root": {
								"& fieldset": {
									borderColor: "#4599d9",
								},
							},
							fontFamily: "'Orbitron', sans-serif",
						}}
					/>
				</Box>
			)}

			{slTpButton && (
				<Box
					sx={{
						display: "flex",
						backgroundColor: "transparent",
						paddingLeft: "15px",
						paddingRight: "15px",
						gap: 4,
						justifyContent: "center",
						alignItems: "center",
						marginTop: "20px",
					}}>
					<Box sx={{ flex: 0.4 }}>
						{/* <Typography sx={{ color: "#ffffff", fontSize: "0.9rem", mt: 1, fontFamily: "'Orbitron', sans-serif" }}>
							Take Profit
						</Typography> */}
						<TextField
							fullWidth
							type="number"
							size="small"
							label={<span style={{ fontFamily: "'Orbitron', sans-serif" }}>Stop Loss</span>}
							value={stopLoss ? stopLoss : slPrice ? Number(slPrice).toFixed(2) : 0}
							onChange={(t) => {
								store.getState().setSlPrice(t.target.value);
								setStopLoss(t.target.value);
								window.handleManualSL(t.target.value);
							}}
							InputProps={{
								style: { color: "#ffffff", fontFamily: "'Orbitron', sans-serif", fontSize: "0.8rem" },
							}}
							sx={{
								backgroundColor: "#161a25",
								borderRadius: "5px",
								width: "100%",
								margin: "0 auto",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "#4599d9",
									},
								},
								fontFamily: "'Orbitron', sans-serif",
							}}
						/>
					</Box>
					<Box sx={{ flex: 0.4 }}>
						{/* <Typography sx={{ color: "#ffffff", fontSize: "0.9rem", mt: 1, fontFamily: "'Orbitron', sans-serif" }}>Stop Loss</Typography> */}
						<TextField
							fullWidth
							type="number"
							size="small"
							label={<span style={{ fontFamily: "'Orbitron', sans-serif" }}>Take Profit</span>}
							value={takeProfit ? takeProfit : tpPrice ? Number(tpPrice).toFixed(2) : 0}
							onChange={(t) => {
								store.getState().setTpPrice(t.target.value);
								setTakeProfit(t.target.value);
								window.handleManualTP(t.target.value);
							}}
							InputProps={{
								style: { color: "#ffffff", fontFamily: "'Orbitron', sans-serif", fontSize: "0.8rem" },
							}}
							sx={{
								backgroundColor: "#161a25",
								borderRadius: "5px",
								width: "100%",
								margin: "0 auto",
								"& .MuiOutlinedInput-root": {
									"& fieldset": {
										borderColor: "#4599d9",
									},
								},
								fontFamily: "'Orbitron', sans-serif",
							}}
						/>
					</Box>
				</Box>
			)}

			{tradeType === "multipliers" ? (
				<>
					<Box
						sx={{
							display: "flex",
							gap: "10px",
							backgroundColor: "transparent",
							padding: "10px",
							borderBottomLeftRadius: "10px",
							borderBottomRightRadius: "10px",
							pt: tradeType === "multipliers" ? "25px" : "0px",
						}}>
						<Box
							sx={{
								flex: 1,
								height: "35px",
								p: 0.5,
								borderRadius: 1,
								transition: "all 0.3s ease",
								cursor: "pointer",
								textAlign: "center",
								color: "#fff",
								fontSize: "0.7rem",
								fontFamily: "'Orbitron', sans-serif",
								lineHeight: 1,
								backgroundColor: trailingStop ? "#01a79e" : "#808080", // Cambiar color si está activo
								textShadow: "0 0 1px #fff",
								boxShadow: trailingStop ? "0 0 5px #01a79e, 0 0 10px #01a79e" : "0 0 5px #808080, 0 0 10px #808080",
								"&:hover": {
									backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
									boxShadow: "0 0 15px #01a79e, 0 0 30px #01a79e",
								},
							}}
							onClick={() => {
								window.startTrailingStopInterval(!trailingStop);
							}}>
							Trailing Stop
						</Box>
						<Box
							sx={{
								flex: 1,
								height: "35px",
								p: 0.5,
								borderRadius: 1,
								transition: "all 0.3s ease",
								cursor: "pointer",
								textAlign: "center",
								color: "#fff",
								fontSize: "0.7rem",
								fontFamily: "'Orbitron', sans-serif",
								lineHeight: 1,
								backgroundColor: slTpButtonColor,
								textShadow: "0 0 1px #fff",
								boxShadow: slTpButtonColor === "#808080" ? "0 0 5px #808080, 0 0 10px #808080" : "0 0 5px #01a79e, 0 0 10px #01a79e",
								"&:hover": {
									backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#01a79e",
									boxShadow: "0 0 15px #01a79e, 0 0 30px #01a79e",
								},
							}}
							onClick={() => window.setBreakEven()}>
							Break Even
						</Box>

						<Button
							variant="contained"
							size="small"
							sx={{
								flex: 1,
								height: "35px",
								p: 0.5,
								borderRadius: 1,
								transition: "all 0.3s ease",
								cursor: "pointer",
								textAlign: "center",
								color: "#fff",
								fontSize: "0.7rem",
								fontFamily: "'Orbitron', sans-serif",
								lineHeight: 1,
								backgroundColor: retCandles ? "#01a79e" : slTpButtonColor, // Cambiar color si está activo
								textShadow: "0 0 1px #fff",
								boxShadow: retCandles
									? "0 0 5px #01a79e, 0 0 10px #01a79e"
									: slTpButtonColor === "#808080"
									? "0 0 5px #808080, 0 0 10px #808080"
									: "0 0 5px #01a79e, 0 0 10px #01a79e",
								"&:hover": {
									backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#808080",
									boxShadow: "0 0 15px #01a79e, 0 0 30px #01a79e",
								},
								"&:active": {
									backgroundColor: "#01a79e", // Color azul cuando el botón está activo
									boxShadow: "0 0 5px #01a79e, 0 0 10px #01a79e",
								},
							}}
							onClick={async () => {
								await window.handleSLTPButtonClick();
							}}>
							Boom Crash
						</Button>
						<Button
							variant="contained"
							size="small"
							sx={{
								flex: 1,
								height: "35px",
								p: 0.5,
								borderRadius: 1,
								transition: "all 0.3s ease",
								cursor: "pointer",
								textAlign: "center",
								color: "#fff",
								fontSize: "0.7rem",
								fontFamily: "'Orbitron', sans-serif",
								backgroundColor: slTpButton ? "#01a79e" : slTpButtonColor, // Cambiar color si está presionado
								textShadow: "0 0 1px #fff",
								boxShadow: slTpButton
									? "0 0 5px #01a79e, 0 0 10px #01a79e"
									: slTpButtonColor === "#808080"
									? "0 0 5px #808080, 0 0 10px #808080"
									: "0 0 5px #01a79e, 0 0 10px #01a79e",
								"&:hover": {
									backgroundColor: slTpButtonColor === "#808080" ? "#696969" : "#808080",
									boxShadow: "0 0 15px #01a79e, 0 0 30px #01a79e",
								},
								"&:active": {
									backgroundColor: "#01a79e", // Color azul cuando el botón está activo
									boxShadow: "0 0 5px #01a79e, 0 0 10px #01a79e",
								},
							}}
							onClick={() => {
								setStopLoss(null);
								window.drawSLTPLines();
							}}>
							SL / TP
						</Button>
					</Box>
				</>
			) : (
				<Box
					sx={{
						display: "flex",
						gap: "10px",
						backgroundColor: "#20232a",
						padding: "10px",
						borderBottomLeftRadius: "10px",
						borderBottomRightRadius: "10px",
						pt: tradeType !== "multipliers" ? "25px" : "0px",
					}}>
					<Box
						sx={{
							flex: 1,
							height: "35px",
							p: 0.5,
							borderRadius: 1,
							backgroundColor: "#cc2e3d",
							boxShadow: "0 0 10px #cc2e3d, 0 0 30px #cc2e3d",
							transition: "all 0.3s ease",
							cursor: "pointer",
							textAlign: "center",
							color: "#fff",
							fontSize: "0.7rem",
							fontFamily: "'Orbitron', sans-serif",
							lineHeight: 1,
							"&:hover": {
								transform: "scale(1.12)",
								boxShadow: "0 0 15px #cc2e3d, 0 0 40px #cc2e3d",
							},
						}}>
						RISE FALL
					</Box>
					<Box
						sx={{
							flex: 1,
							height: "35px",
							p: 0.5,
							borderRadius: 1,
							backgroundColor: "#cc2e3d",
							boxShadow: "0 0 10px #cc2e3d, 0 0 30px #cc2e3d",
							transition: "all 0.3s ease",
							cursor: "pointer",
							textAlign: "center",
							color: "#fff",
							fontSize: "0.7rem",
							fontFamily: "'Orbitron', sans-serif",
							lineHeight: 1,
							"&:hover": {
								transform: "scale(1.12)",
								boxShadow: "0 0 15px #cc2e3d, 0 0 40px #cc2e3d",
							},
						}}>
						HIGHER LOWER
					</Box>
					<Box
						sx={{
							flex: 1,
							height: "35px",
							p: 0.5,
							borderRadius: 1,
							backgroundColor: "#cc2e3d",
							boxShadow: "0 0 10px #cc2e3d, 0 0 30px #cc2e3d",
							transition: "all 0.3s ease",
							cursor: "pointer",
							textAlign: "center",
							color: "#fff",
							fontSize: "0.7rem",
							fontFamily: "'Orbitron', sans-serif",
							lineHeight: 1,
							"&:hover": {
								transform: "scale(1.12)",
								boxShadow: "0 0 15px #cc2e3d, 0 0 40px #cc2e3d",
							},
						}}>
						TOUCH NOTOUCH
					</Box>
					<Box
						sx={{
							flex: 1,
							height: "35px",
							p: 0.5,
							borderRadius: 1,
							backgroundColor: "#cc2e3d",
							boxShadow: "0 0 10px #cc2e3d, 0 0 30px #cc2e3d",
							transition: "all 0.3s ease",
							cursor: "pointer",
							textAlign: "center",
							color: "#fff",
							fontSize: "0.7rem",
							fontFamily: "'Orbitron', sans-serif",
							lineHeight: 1,
							"&:hover": {
								transform: "scale(1.12)",
								boxShadow: "0 0 15px #cc2e3d, 0 0 40px #cc2e3d",
							},
						}}>
						ACCUM
					</Box>
				</Box>
			)}

			<Box
				sx={{
					width: "100%",
					mt: 2,
					borderTop: "2px solid rgba(69, 153, 217, 0.2)",
				}}>
				<Typography
					sx={{
						color: "#fff",
						fontSize: "0.8rem",
						fontFamily: "'Orbitron', sans-serif",
						textAlign: "center",
						py: 1,
					}}>
					Posiciones Activas
				</Typography>
				<Box
					sx={{
						maxHeight: "200px",
						"&::-webkit-scrollbar": {
							width: "8px",
						},
						"&::-webkit-scrollbar-track": {
							background: "#1a1f2d",
						},
						"&::-webkit-scrollbar-thumb": {
							background: "#4599d9",
							borderRadius: "4px",
						},
					}}>
					{reports.map((row) => (
						<Box
							key={row.contract_id}
							sx={{
								display: "flex",
								flexDirection: "column",
								backgroundColor: "rgba(23, 27, 38, 0.9)",
								padding: "8px 12px",
								borderRadius: "8px",
								margin: "4px",
								marginBottom: "10px",
								cursor: "pointer",
								transition: "all 0.3s ease",
								boxShadow: "0 0 5px #4599d9, 0 0 10px #4599d9",
								"&:hover": {
									backgroundColor: "rgba(69, 153, 217, 0.2)",
									boxShadow: "0 0 15px rgba(69, 153, 217, 0.3)",
								},
							}}
							onClick={() => handleCellClick(row)}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									mb: 0.5,
								}}>
								<Box
									sx={{
										fontSize: "0.65rem",
										color: "#4599d9",
										fontFamily: "'Orbitron', sans-serif",
									}}>
									{new Date(row.date_start * 1000).toLocaleString()}
								</Box>
								<Box
									sx={{
										fontSize: "0.65rem",
										color: "#fff",
										fontFamily: "'Orbitron', sans-serif",
									}}>
									{row.contract_type}
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}>
								<Box
									sx={{
										fontSize: "0.7rem",
										color: "#fff",
										fontFamily: "'Orbitron', sans-serif",
									}}>
									{row.display_name}
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
									}}>
									<Box
										sx={{
											fontSize: "0.7rem",
											color: row.profit > 0 ? "#2eb62e" : "#f74712",
											fontFamily: "'Orbitron', sans-serif",
										}}>
										{row.profit}
									</Box>
									<IconButton
										onClick={(e) => {
											e.stopPropagation();
											handleDelete(row);
										}}
										size="small"
										sx={{
											color: "#f74712",
											padding: "2px",
											"&:hover": {
												backgroundColor: "rgba(247, 71, 18, 0.1)",
											},
										}}>
										<CloseIcon sx={{ fontSize: "1rem" }} />
									</IconButton>
								</Box>
							</Box>
						</Box>
					))}
				</Box>
			</Box>

			<Box
				sx={{
					position: "absolute",
					bottom: 0,
					width: "100%",
					padding: "15px",
					backgroundColor: "#20232a",
					borderTop: "1px solid rgba(69, 153, 217, 0.2)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gap: 2,
				}}>
				<Typography
					onClick={() => setTradeType("multipliers")}
					sx={{
						color: tradeType === "multipliers" ? "#4599d9" : "#fff",
						fontSize: "0.8rem",
						fontFamily: "'Orbitron', sans-serif",
						textShadow: tradeType === "multipliers" ? "0 0 10px #4599d9" : "none",
						transition: "all 0.3s ease",
						cursor: "pointer",
						"&:hover": {
							color: "#4599d9",
							textShadow: "0 0 10px #4599d9",
						},
					}}>
					Multiplicadores
				</Typography>
				<Switch
					checked={tradeType === "options"}
					onChange={(e) => setTradeType(e.target.checked ? "options" : "multipliers")}
					sx={{
						"& .MuiSwitch-switchBase": {
							color: "#4599d9",
							"&.Mui-checked": {
								color: "#cc2e3d",
							},
							"&.Mui-checked + .MuiSwitch-track": {
								backgroundColor: "#cc2e3d",
							},
						},
						"& .MuiSwitch-track": {
							backgroundColor: "#4599d9",
						},
						transform: "scale(1.2)",
					}}
				/>
				<Typography
					onClick={() => setTradeType("options")}
					sx={{
						color: tradeType === "options" ? "#cc2e3d" : "#fff",
						fontSize: "0.8rem",
						fontFamily: "'Orbitron', sans-serif",
						textShadow: tradeType === "options" ? "0 0 10px#cc2e3d" : "none",
						transition: "all 0.3s ease",
						cursor: "pointer",
						"&:hover": {
							color: "#cc2e3d",
							textShadow: "0 0 10px#cc2e3d",
						},
					}}>
					Opciones
				</Typography>
			</Box>
		</Box>
	);
};

export default NewSidePanel;
